import { CourseAppFormProvider } from "./Form"
import LanguageState from "./Lang"
import { PageProvider } from "./Page"
import { VariableProvider } from "./Variable"

function Context({ children }: { children: any }) {
    return (
        <VariableProvider>
            <LanguageState>
                <PageProvider>
                    <CourseAppFormProvider>
                        {children}
                    </CourseAppFormProvider >
                </PageProvider>
            </LanguageState>
        </VariableProvider >
    )
}

export default Context