import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Lang } from "../../../components";
import {
  IconEmail,
  IconGeo,
  IconMobile,
} from "../../../components/common/Icons";
import { DetailLoading } from "../../../components/common/Loading";
import { LangContext } from "../../../context/Lang";
import { handleShow } from "../../../services";
import { getRequest } from "../../../services/fetch";

const RES_API_URL = process.env.REACT_APP_RES_API_URL;

interface Target {
  id?: number;
  itemName?: string;
  image?: string;
}

const CenterDetailCard = () => {
  const [loading, setLoading] = useState(false);
  const [centerDetail, setCenterDetail] = useState<any>();
  // const [imageView, setImageView] = useState({ photoIndex: 0, isOpen: false });
  // const handleCloseImageModal = () =>
  //   setImageView({ ...imageView, isOpen: false });
  const location: any = useLocation();
  const id = location?.state.id;
  // const images = ["images/IDGVA_Logo.png", "images/IDGVA_Logo.png"];

  const [, setTarget] = useState<Target>();
  const {
    state: { language },
  } = useContext(LangContext);
  const [, setShow] = useState(false);

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = async () => {
    setLoading(true);
    await getRequest({
      route: `center/${id}`,
      isPrivate: false,
    }).then((result) => {
      setCenterDetail(result.data);
      setInterval(() => {
        setLoading(false);
      }, 1000);
    });
  };

  const thumbsnailImage = (item: any) => {
    let tempImages = item?.centerImages;
    let isExist = false;
    let tempDiv = (
      <img
        src="images/IDGVA_Logo.png"
        alt="Thumbnail"
        className="float-right inv-thumb"
      />
    );

    if (tempImages && tempImages.length > 0) {
      tempImages.forEach((img: any) => {
        if (img.thumbnail) {
          isExist = true;
          tempDiv = (
            <img
              src={`${RES_API_URL}${img.url}`}
              alt="no img"
              className="float-right inv-thumb cursor"
              onClick={() => {
                setTarget({ itemName: item?.centerNameEN, image: img.url });
                handleShow(setShow);
              }}
            />
          );
        }
      });
      if (!isExist) {
        tempDiv = (
          <img
            src={`${RES_API_URL}${tempImages[0].url}`}
            alt="no img"
            className="float-right inv-thumb cursor"
            onClick={() => {
              setTarget({ itemName: item?.itemName, image: tempImages[0].url });
              handleShow(setShow);
            }}
          />
        );
      }
    }
    return tempDiv;
  };
  return (
    <>
      {loading ? (
        <DetailLoading />
      ) : (
        <Container className="my-5">
          {/* <Row className=''> */}
          <div className="bg-card-no-hover p-4 ">
            <Row>
              <Col md={6}>
                <Col>
                  <div className="d-flex mb-3 detail-img">
                    {/* <Image src={item.image} /> */}

                    {thumbsnailImage(centerDetail)}
                  </div>

                  {/* <Row>
                  <Col md={4}>
                    <Button
                      className="btn-yel"
                      onClick={() => {
                        setImageView({
                          ...imageView,
                          photoIndex: item.index,
                          isOpen: true,
                        })
                      }}
                    >
                      <Lang label="seeMorePhoto" />
                    </Button>
                  </Col>
                </Row> */}
                </Col>
              </Col>

              <Col md={6} sm={12} className="">
                <div className="text-left my-3 ">
                  <span className="fw-bold fs-5">
                    {language === "EN"
                      ? centerDetail?.centerNameEN
                      : centerDetail?.centerNameMM}
                  </span>
                  {/* <Col md="6" sm="12" className='contact'> */}
                  <div className="contact">
                    <div className="box">
                      <div className="icon">
                        {/* <FaEnvelope /> */}
                        <IconEmail size={20} />
                      </div>
                      <div>
                        <h6 className="fw-bold">
                          <Lang label="email" />
                        </h6>
                        <span>
                          <span>{centerDetail?.email}</span>
                        </span>
                      </div>
                    </div>
                    <div className="box">
                      <div className="icon">
                        <IconMobile size={20} />
                        {/* <FaMobileAlt /> */}
                      </div>
                      <div>
                        <h6 className="fw-bold">
                          <Lang label="phoneNumber" />
                        </h6>
                        <span>
                          <span>
                            {centerDetail?.phone}
                            <br />
                            {centerDetail?.phoneOther}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="box">
                      <div className="icon">
                        {/* <FaMapMarkerAlt /> */}
                        <IconGeo size={20} color="currentColor" />
                      </div>
                      <div>
                        <h6 className="fw-bold">
                          <Lang label="addressLabel" />
                        </h6>
                        <span>
                          {language === "EN"
                            ? centerDetail?.addressEN
                            : centerDetail?.addressMM}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Col className="px-2 pb-5 mt-2">
              <h5 className="fw-bold">
                <Lang label="about" />
              </h5>
              <span>
                {language === "EN"
                  ? centerDetail?.aboutCenterEN
                  : centerDetail?.aboutCenterMM}
              </span>
            </Col>
          </div>
        </Container>
      )}
    </>
  );
};
export default CenterDetailCard;
