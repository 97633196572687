import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { publicRoutes } from "./routes";
import { ScrollToTopPage } from "./components";
import "./assets/sass/index.scss";
import "react-loading-skeleton/dist/skeleton.css";
import "react-image-lightbox/style.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Router>
      <ScrollToTopPage />
      <Routes>
        {publicRoutes.map((item: any, index: number) => (
          <Route
            path={`/${item.route}`}
            element={<item.component />}
            key={index}
          />
        ))}
      </Routes>
    </Router>
  );
}

export default App;
