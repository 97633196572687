import { useContext } from "react";
import { Table } from "react-bootstrap";
import { Lang } from "../../../../components";
import { CourseAppFormContext } from "../../../../context/Form";

const OldStudentTable = () => {
  const [courseAppForm, setCourseAppForm] = useContext(CourseAppFormContext);
  const data = [
    {
      id: "server10Days",
      id2: "student10Days",
      title: <Lang label="10day" />,
      data: courseAppForm.server10Days,
      _data: courseAppForm.student10Days,
    },
    {
      id: "serverSathi",
      id2: "studentSathi",
      title: <Lang label="STP" />,
      data: courseAppForm.serverSathi,
      _data: courseAppForm.studentSathi,
    },
    {
      id: "server20Days",
      id2: "student20Days",
      title: <Lang label="20day" />,
      data: courseAppForm.server20Days,
      _data: courseAppForm.student20Days,
    },
    {
      id: "server30Days",
      id2: "student30Days",
      title: <Lang label="30day" />,
      data: courseAppForm.server30Days,
      _data: courseAppForm.student30Days,
    },
    {
      id: "server45Days",
      id2: "student45Days",
      title: <Lang label="45day" />,
      data: courseAppForm.server45Days,
      _data: courseAppForm.student45Days,
    },
    {
      id: "server60Days",
      id2: "student60Days",
      title: <Lang label="60day" />,
      data: courseAppForm.server60Days,
      _data: courseAppForm.student60Days,
    },
    {
      id: "serverTSC",
      id2: "studentTSC",
      title: <Lang label="GC" />,
      data: courseAppForm.serverTSC,
      _data: courseAppForm.studentTSC,
    },
    {
      id: "serverSelf",
      id2: "studentSelf",
      title: <Lang label="self" />,
      data: courseAppForm.serverSelf,
      _data: courseAppForm.studentSelf,
    },
    {
      id: "serverSpecial10Days",
      id2: "studentSpecial10Days",
      title: <Lang label="10Dspecial" />,
      data: courseAppForm.serverSpecial10Days,
      _data: courseAppForm.studentSpecial10Days,
    },
    {
      id: "",
      title: <Lang label="total" />,
      data:
        parseInt(courseAppForm?.server10Days || 0) +
        parseInt(courseAppForm?.serverSathi || 0) +
        parseInt(courseAppForm?.server20Days || 0) +
        parseInt(courseAppForm?.server30Days || 0) +
        parseInt(courseAppForm?.server45Days || 0) +
        parseInt(courseAppForm?.server60Days || 0) +
        parseInt(courseAppForm?.serverTSC || 0) +
        parseInt(courseAppForm?.serverSelf || 0) +
        parseInt(courseAppForm?.serverSpecial10Days || 0),
      _data:
        parseInt(courseAppForm?.student10Days || 0) +
        parseInt(courseAppForm?.studentSathi || 0) +
        parseInt(courseAppForm?.student20Days || 0) +
        parseInt(courseAppForm?.student30Days || 0) +
        parseInt(courseAppForm?.student45Days || 0) +
        parseInt(courseAppForm?.student60Days || 0) +
        parseInt(courseAppForm?.studentTSC || 0) +
        parseInt(courseAppForm?.studentSelf || 0) +
        parseInt(courseAppForm?.studentSpecial10Days || 0),
    },
  ];

  return (
    <>
      <div className="my-3 bg-card p-4">
        <div className="d-none d-lg-block d-md-block">
          <Table bordered={true} responsive striped>
            <thead>
              <tr>
                <th></th>
                {data.map((item: any, index: number) => (
                  <td key={index}>
                    <span className="flex-center">{item.title}</span>
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              <>
                <tr>
                  <td className="text-center ">
                    {" "}
                    <span className="flex-center">
                      <Lang label="courses" />
                    </span>
                  </td>
                  {data.map((item: any, index: number) => (
                    <td className="text-center" key={index}>
                      <span className="flex-center">
                        <input
                          type="number "
                          value={item._data}
                          className="w-3 text-center form-control"
                          onChange={(e) =>
                            setCourseAppForm({
                              ...courseAppForm,
                              [item.id2]: e.target.value,
                            })
                          }
                        />
                      </span>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="text-center ">
                    <span className="flex-center">
                      <Lang label="server" />
                    </span>
                  </td>
                  {data.map((item: any, index: number) => (
                    <td className="text-center" key={index}>
                      <span className="flex-center">
                        <input
                          type="number "
                          value={item.data}
                          className="w-3 text-center form-control"
                          onChange={(e) =>
                            setCourseAppForm({
                              ...courseAppForm,
                              [item.id]: e.target.value,
                            })
                          }
                        />
                      </span>
                    </td>
                  ))}
                </tr>
              </>
            </tbody>
          </Table>
        </div>
        <div className=" rounded d-sm-none d-md-none">
          <Table bordered={true} responsive striped>
            <thead>
              <tr>
                <th></th>
                <th className="text-center">
                  <span className="flex-center">
                    <Lang label="courses" />
                  </span>
                </th>
                <th className="text-center">
                  <span className="flex-center">
                    <Lang label="services" />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => (
                <tr key={index}>
                  <td className="text-center "> {item.title}</td>
                  <td className="text-center">
                    {" "}
                    <span className="flex-center">
                      <input
                        type="number "
                        value={item._data}
                        className="w-3 text-center form-control"
                        onChange={(e) =>
                          setCourseAppForm({
                            ...courseAppForm,
                            [item.id2]: e.target.value,
                          })
                        }
                      />
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="flex-center">
                      <input
                        value={item.data}
                        type="number "
                        className="w-3 text-center form-control"
                        onChange={(e) =>
                          setCourseAppForm({
                            ...courseAppForm,
                            [item.id]: e.target.value,
                          })
                        }
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};
export default OldStudentTable;
