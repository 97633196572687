import { useContext } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { FieldAccorion, Lang } from "../../../../components";
import OldStudentTable from "./OldStudentTable";
import { CourseAppFormContext } from "../../../../context/Form";

const OldPersonalInfo = () => {
  const [courseAppForm, setCourseAppForm] = useContext(CourseAppFormContext);
  const fields = [
    {
      id: "languagesSkill",
      title: <Lang label="q_2" />,
      detail: "d",
      show: true,
      required: true,
    },
  ];
  const fields2 = [
    {
      id: "noticeChangesInHealth",
      title: <Lang label="oq_3" />,
      description: " ",
      detail: "fa",
      show: true,
      required: true,
    },

    {
      id: "noticechangesInCommunication",
      title: <Lang label="oq_4" />,
      description: "",
      detail: "fa",
      show: true,
      required: true,
    },
    {
      id: "noticeChangesInAddiction",
      title: <Lang label="oq_5" />,
      description: "",
      detail: "fa",
      show: true,
      required: true,
    },
    {
      id: "autoBiography",
      title: <Lang label="oq_6" />,
      description: "",
      detail: "fa",
      show: true,
      required: true,
    },
  ];
  return (
    <>
      <Container>
        <div className="pt-3">
          <Row className="mb-5">
            <Col md={3}>
              <h6 className="fw-bold my-3">
                <Lang label="firstDate" />
              </h6>
              <Form.Control
                required
                type="date"
                placeholder=" "
                value={courseAppForm.firstCourseDate}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    firstCourseDate: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={3}>
              <h6 className="fw-bold my-3">
                <Lang label="teachers" />
              </h6>
              <Form.Control
                as="textarea"
                placeholder=""
                value={courseAppForm.firstCourseTeacher}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    firstCourseTeacher: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={3}>
              <h6 className="fw-bold my-3">
                <Lang label="centerLocation" />
              </h6>
              <Form.Control
                type="text"
                placeholder=""
                value={courseAppForm.firstCourseCenterName}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    firstCourseCenterName: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={3}>
              <h6 className="fw-bold my-3">
                <Lang label="city" />
              </h6>
              <Form.Control
                required
                type="text"
                placeholder=""
                value={courseAppForm.firstCourseCenterTownship}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    firstCourseCenterTownship: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={3}>
              <h6 className="fw-bold my-3">
                <Lang label="secondDate" />
              </h6>
              <Form.Control
                required
                type="date"
                placeholder=" "
                value={courseAppForm.recentCourseDate}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    recentCourseDate: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={3}>
              <h6 className="fw-bold my-3">
                <Lang label="teachers" />
              </h6>
              <Form.Control
                as="textarea"
                placeholder=""
                value={courseAppForm.recentCourseTeacher}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    recentCourseTeacher: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={3}>
              <h6 className="fw-bold my-3">
                <Lang label="centerLocation" />
              </h6>
              <Form.Control
                type="text"
                placeholder=""
                value={courseAppForm.recentCourseCenterName}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    recentCourseCenterName: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={3}>
              <h6 className="fw-bold my-3">
                <Lang label="city" />
              </h6>
              <Form.Control
                required
                type="text"
                placeholder=""
                value={courseAppForm.recentCourseCenterTownship}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    recentCourseCenterTownship: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <div className="my-5">
            <Lang label="record_des" />
          </div>
          <OldStudentTable />
          <FieldAccorion label={<Lang label="q_1" />}>
            <Form.Check
              required
              inline
              label={<Lang label="yes" />}
              type={"radio"}
              id={"isYourSpouseAttendeeYes"}
              name="isYourSpouseAttendee"
              checked={courseAppForm.isYourSpouseAttendee}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isYourSpouseAttendee: true,
                })
              }
            />
            <Form.Check
              inline
              label={<Lang label="no" />}
              name="isYourSpouseAttendee"
              type={"radio"}
              id={`isYourSpouseAttendeeNo`}
              checked={!courseAppForm.isYourSpouseAttendee}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isYourSpouseAttendee: false,
                })
              }
            />
          </FieldAccorion>
          {fields.map(
            (item: any, index: number) =>
              item.show && (
                <FieldAccorion label={item.title} key={index}>
                  {item.detail && (
                    <>
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          required={item.required}
                          as="textarea"
                          rows={3}
                          value={courseAppForm[item.id]}
                          onChange={(e) =>
                            setCourseAppForm({
                              ...courseAppForm,
                              [item.id]: e.target.value,
                            })
                          }
                        ></Form.Control>
                      </Form.Group>
                    </>
                  )}
                </FieldAccorion>
              )
          )}
          <FieldAccorion label={<Lang label="oq_1" />}>
            <Form.Check
              inline
              label={<Lang label="yes" />}
              type={"radio"}
              id={"isPracticeMeditaitonTeqYes"}
              name="isPracticeMeditaitonTeq"
              checked={courseAppForm.isMaintainPractice}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isMaintainPractice: true,
                })
              }
            />
            <Form.Check
              inline
              label={<Lang label="no" />}
              name="isPracticeMeditaitonTeq"
              type={"radio"}
              id={`isPracticeMeditaitonTeqNo`}
              checked={!courseAppForm.isMaintainPractice}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isMaintainPractice: false,
                  maintainPracticeDetail: "",
                })
              }
            />
          </FieldAccorion>
          {courseAppForm.isMaintainPractice && (
            <FieldAccorion label={<Lang label="detail_fill" />}>
              <Form.Control
                as="textarea"
                rows={3}
                value={courseAppForm.maintainPracticeDetail}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    maintainPracticeDetail: e.target.value,
                  })
                }
              />
            </FieldAccorion>
          )}
          <FieldAccorion label={<Lang label="oq_2" />}>
            <Form.Check
              inline
              label={<Lang label="yes" />}
              type={"radio"}
              id={"isMaintainPracticeYes"}
              name="isMaintainPractice"
              checked={courseAppForm.isPracticeOtherMeditaitonTeq}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isPracticeOtherMeditaitonTeq: true,
                })
              }
            />
            <Form.Check
              inline
              label={<Lang label="no" />}
              name="isMaintainPractice"
              type={"radio"}
              id={`isMaintainPracticeNo`}
              checked={!courseAppForm.isPracticeOtherMeditaitonTeq}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isPracticeOtherMeditaitonTeq: false,
                  otherMeditaitonTeqDetail: "",
                })
              }
            />
          </FieldAccorion>
          {courseAppForm.isPracticeOtherMeditaitonTeq && (
            <FieldAccorion label={<Lang label="detail_fill" />}>
              <Form.Control
                as="textarea"
                rows={3}
                value={courseAppForm.otherMeditaitonTeqDetail}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    otherMeditaitonTeqDetail: e.target.value,
                  })
                }
              />
            </FieldAccorion>
          )}

          <FieldAccorion label={<Lang label="q_5" />}>
            <Form.Check
              inline
              label={<Lang label="yes" />}
              type={"radio"}
              id={"illnessMentalYes"}
              name="illnessMentalYes"
              checked={courseAppForm.isIllnessMental}
              onChange={() =>
                setCourseAppForm({ ...courseAppForm, isIllnessMental: true })
              }
            />
            <Form.Check
              inline
              label={<Lang label="no" />}
              name="illnessMentalNo"
              type={"radio"}
              id={`illnessMentalNo`}
              checked={!courseAppForm.isIllnessMental}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isIllnessMental: false,
                  IllnessMentalDetail: "",
                })
              }
            />
          </FieldAccorion>
          {courseAppForm.isIllnessMental && (
            <FieldAccorion label={<Lang label="detail_fill" />}>
              <Form.Control
                as="textarea"
                rows={3}
                value={courseAppForm.IllnessMentalDetail}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    IllnessMentalDetail: e.target.value,
                  })
                }
              />
            </FieldAccorion>
          )}
          {/* //isIllenssDetail */}
          <FieldAccorion label={<Lang label="q_4" />}>
            <Form.Check
              inline
              label={<Lang label="yes" />}
              type={"radio"}
              id={"illnessPhysicalYes"}
              name="illnessPhysicalYes"
              checked={courseAppForm.isIllnessPhysical}
              onChange={() =>
                setCourseAppForm({ ...courseAppForm, isIllnessPhysical: true })
              }
            />
            <Form.Check
              inline
              label={<Lang label="no" />}
              name="illnessPhysicalYes"
              type={"radio"}
              id={`illnessPhysicalNo`}
              checked={!courseAppForm.isIllnessPhysical}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isIllnessPhysical: false,
                  IllnessPhysicalDetail: "",
                })
              }
            />
          </FieldAccorion>
          {courseAppForm.isIllnessPhysical && (
            <FieldAccorion label={<Lang label="detail_fill" />}>
              <Form.Control
                as="textarea"
                rows={3}
                value={courseAppForm.IllnessPhysicalDetail}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    IllnessPhysicalDetail: e.target.value,
                  })
                }
              />
            </FieldAccorion>
          )}
          <FieldAccorion label={<Lang label="q_6" />}>
            <Form.Check
              inline
              label={<Lang label="yes" />}
              type={"radio"}
              id={"practicedMeditationTechniquesYes"}
              name="practicedMeditationTechniques"
              checked={courseAppForm.isPracticeOtherTeqInPast}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isPracticeOtherTeqInPast: true,
                })
              }
            />
            <Form.Check
              inline
              label={<Lang label="no" />}
              name="practicedMeditationTechniques"
              type={"radio"}
              id={`practicedMeditationTechniquesYesNO`}
              checked={!courseAppForm.isPracticeOtherTeqInPast}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isPracticeOtherTeqInPast: false,
                  otherTeqInPastDetail: "",
                })
              }
            />
          </FieldAccorion>
          {courseAppForm.isPracticeOtherTeqInPast && (
            <FieldAccorion label={<Lang label="detail_fill" />}>
              <Form.Control
                as="textarea"
                rows={3}
                value={courseAppForm.otherTeqInPastDetail}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    otherTeqInPastDetail: e.target.value,
                  })
                }
              />
            </FieldAccorion>
          )}
          <FieldAccorion label={<Lang label="q_7" />}>
            <Form.Check
              inline
              label={<Lang label="yes" />}
              type={"radio"}
              id={"addictedDrugsAlcoholDetailYes"}
              name="addictedDrugsAlcohol"
              checked={courseAppForm.isAddictedDrugsAlcohol}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isAddictedDrugsAlcohol: true,
                })
              }
            />
            <Form.Check
              inline
              label={<Lang label="no" />}
              name="addictedDrugsAlcohol"
              type={"radio"}
              id={`addictedDrugsAlcoholDetailNo`}
              checked={!courseAppForm.isAddictedDrugsAlcohol}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isAddictedDrugsAlcohol: false,
                  addictedDrugsAlcoholDetail: "",
                })
              }
            />
          </FieldAccorion>
          {courseAppForm.isAddictedDrugsAlcohol && (
            <FieldAccorion label={<Lang label="detail_fill" />}>
              <Form.Control
                as="textarea"
                rows={3}
                value={courseAppForm.addictedDrugsAlcoholDetail}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    addictedDrugsAlcoholDetail: e.target.value,
                  })
                }
              />
            </FieldAccorion>
          )}
          {fields2.map(
            (item: any, index: number) =>
              item.show && (
                <FieldAccorion label={item.title} key={index}>
                  {item.detail && (
                    <>
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          required={item.required}
                          as="textarea"
                          rows={3}
                          value={courseAppForm[item.id]}
                          onChange={(e) =>
                            setCourseAppForm({
                              ...courseAppForm,
                              [item.id]: e.target.value,
                            })
                          }
                        ></Form.Control>
                      </Form.Group>
                    </>
                  )}
                </FieldAccorion>
              )
          )}
        </div>
      </Container>
    </>
  );
};
export default OldPersonalInfo;
