import Register from "./centers/cpn/newstudent/Register";
import Centers from "./centers";
import Home from "./home";
import CenterDetail from "./centers/detail/CenterDetail";
import IntroTechnique from "./centers/cpn/IntroTechnique";
import FormComplete from "./centers/cpn/FormComplete";
import RegisterOld from "./centers/cpn/oldstudent/RegisterOld";
import ServerTimetable from "./centers/cpn/server/ServerTimetable";
import OldStudentForm from "./centers/cpn/oldstudent/OldStudentForm";
import NewStudentForm from "./centers/cpn/newstudent/NewStudentForm";
import ServerForm from "./centers/cpn/server/ServerForm";
import LongCourseForm from "./centers/cpn/longcourseform/LongCourseForm";
import SecondPage from "./centers/cpn/longcourseform/SecondPage";

export const publicRoutes = [
  { route: "", component: Home },
  { route: "old-student-form", component: OldStudentForm },
  { route: "new-student-form", component: NewStudentForm },
  { route: "server-form", component: ServerForm },
  { route: "centers", component: Centers },
  { route: "center-detail", component: CenterDetail },
  { route: "cod", component: IntroTechnique },
  { route: "dhamma-worker-timetable", component: ServerTimetable },
  { route: "registration-complete", component: FormComplete },
  { route: "registration-form-new", component: Register },
  { route: "registration-form-old", component: RegisterOld },
  { route: "long-course-registration-form", component: LongCourseForm },
  { route: "long-course-registration", component: SecondPage },
];

export const privateRoutes = [];
