import { useContext } from "react";
import Layout from "../../components/layout/Layout";
import { VariableContext } from "../../context/Variable";
import OfficeMap from "../map/OfficeMap";
import GridLayout from "./centerview/Grid";
import ListLayout from "./centerview/Lists";

const Centers = () => {
  const [variable] = useContext(VariableContext);
  return (
    <>
      <Layout>
        {variable?.activeType === "map" ? (
          <OfficeMap />
        ) : variable?.activeType === "list" ? (
          <ListLayout />
        ) : (
          <GridLayout />
        )}
      </Layout>
    </>
  );
};
export default Centers;
