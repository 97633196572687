import { useContext, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Lang } from '..'
import { CourseAppFormContext } from '../../context/Form'
import { actionData, getMethod } from '../../services/api'
import { getRequest } from '../../services/fetch'

const NRCPassport = () => {
  const [courseAppForm, setCourseAppForm] = useContext(CourseAppFormContext)
  const [data, setData] = useState<any>([])
  const [area, setArea] = useState<any>([])
  const [nrcTypeEN, setNrcTypeEN] = useState<any>([])
  const [, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    id_info: 'nrc_detail',
    idCard: '',
    nrc: '',
    gender: '',
    nrcCode: '',
    nrcArea: '',
    nrcType: '',
    nrcNumber: '',
  })

  const getOjectValue = (objectKey: string) => {
    let objectValue = ''
    for (let [key, value] of Object.entries(formData)) {
      if (key === objectKey) {
        objectValue = value
      }
    }
    return objectValue
  }

  const fields = [
    {
      id: 'id_info',
      options: [
        { label: <Lang label="nrc" />, value: 'nrc_detail' },
        { label: <Lang label="passport" />, value: 'passport' },
      ],
      show: true,
    },
    {
      id: 'idType',
      detail: 'passport_field',
      show: formData.id_info === 'passport',
    },
    {
      id: 'nrc',
      detail: 'nrc_form',
      show: formData.id_info === 'nrc_detail',
    },
  ]
  useEffect(() => {
    handleGetData()
    // eslint-disable-next-line
  }, [])

  const handleGetData = async () => {
    setLoading(true)
    let nrcType = ''
    await getRequest({ route: 'nrcs/type', isPrivate: false }).then(
      (result) => {
        let temp: any = []
        if (result && result.data.nrcTypeEN.length > 0) {
          result.data.nrcTypeEN.forEach((item: any) => {
            temp.push({ key: item, label: item, customKey: true })
          })
        }
        setNrcTypeEN(temp)
        nrcType = temp[0]?.label
      }
    )
    actionData(getMethod, `nrcs/code`).then((result: any) => {
      setData(result.data)
      handleGetArea(
        result.data[0]?.nrcCode,
        result.data[0]?.nrcCodeDisplayEN,
        nrcType
      )
    })
  }
  const handleGetArea = async (
    nrcCode: any,
    nrcCodeLabel?: string,
    nrcType?: string
  ) => {
    actionData(getMethod, `nrcs/area?nrcCode=` + nrcCode).then((result) => {
      setArea(result.data)
      setFormData({
        ...formData,
        nrcType: nrcType ? nrcType : formData.nrcType,
        nrcCode: nrcCodeLabel ? nrcCodeLabel : formData.nrcCode,
        nrcArea: result.data[0]?.nameENDisplay,
      })
    })
  }
  const handleSelectNrcCode = (e: any) => {
    const value = e.target.value
    let index = e.nativeEvent.target.selectedIndex
    let label = e.nativeEvent.target[index].text
    setCourseAppForm({
      ...courseAppForm,
      idCardNumber: `${label}${formData.nrcArea}${formData.nrcType}${formData.nrcNumber}`,
      idType: 'NRC',
      nrcDigit: value,
    })
    handleGetArea(value, label)
  }
  const handleSelectNrcArea = (e: any) => {
    const value = e.target.value
    setCourseAppForm({
      ...courseAppForm,
      idCardNumber: `${formData.nrcCode}${value}${formData.nrcType}${formData.nrcNumber}`,
      nrcArea: value,
      idType: 'NRC',
    })
    setFormData({ ...formData, nrcArea: e.target.value })
  }
  const handleSelectNrcType = (e: any) => {
    const value = e.target.value
    setCourseAppForm({
      ...courseAppForm,
      idCardNumber: `${formData.nrcCode}${formData.nrcArea}${value}${formData.nrcNumber}`,
      nrcType: value,
      idType: 'NRC',
    })
    setFormData({ ...formData, nrcType: e.target.value })
  }
  const handleSelectNrcNumber = (e: any) => {
    const value = e.target.value
    setCourseAppForm({
      ...courseAppForm,
      idCardNumber: `${formData.nrcCode}${formData.nrcArea}${formData.nrcType}${value}`,
      nrcNumber: value,
      idType: 'NRC',
    })
    setFormData({ ...formData, nrcNumber: e.target.value })
  }

  return (
    <>
      <div className="mt-2 mb-3">
        <h6 className="my-4">
          <Lang label="id_detail" />
        </h6>

        {fields.map(
          (item: any, index: number) =>
            item.show && (
              <div key={index}>
                {item.detail === 'passport_field' ? (
                  <>
                    <Form.Group className="my-3" controlId="">
                      <Form.Control
                        required
                        as="textarea"
                        rows={1}
                        value={courseAppForm.idCardNumber}
                        onChange={(e) =>
                          setCourseAppForm({
                            ...courseAppForm,
                            idCardNumber: e.target.value,
                            idType: 'Passport',
                          })
                        }
                      ></Form.Control>
                    </Form.Group>
                  </>
                ) : item.detail === 'nrc_form' ? (
                  <>
                    <Row>
                      <>
                        {' '}
                        <Col md={2}>
                          <Form.Group className="my-3" controlId="">
                            <Form.Control
                              as="select"
                              value={courseAppForm.nrcDigit}
                              onChange={handleSelectNrcCode}
                            >
                              {data.map((item: any, index: number) => (
                                <option key={index} value={item.nrcCode}>
                                  {item.nrcCodeDisplayEN}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group className="my-3" controlId="">
                            <Form.Control
                              as="select"
                              value={courseAppForm.nrcArea}
                              onChange={handleSelectNrcArea}
                            >
                              {area.map((item: any, index: number) => (
                                <option key={index}>
                                  {item.nameENDisplay}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={2}>
                          <Form.Group className="my-3" controlId="">
                            <Form.Control
                              as="select"
                              value={courseAppForm.nrcType}
                              onChange={handleSelectNrcType}
                            >
                              {nrcTypeEN.map((item: any, index: number) => (
                                <option key={item.key} value={item.key}>
                                  {item.label}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group className="my-3" controlId="">
                            <Form.Control
                              required
                              type="text"
                              value={courseAppForm.nrcNumber}
                              onChange={handleSelectNrcNumber}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </>
                    </Row>
                  </>
                ) : (
                  item.options.map((subItem: any, subIndex: number) => (
                    <Form.Check
                      className="d-inline-block mx-2"
                      type={'radio'}
                      key={subIndex}
                      id={subItem.value}
                      name={item.id}
                      label={subItem.label}
                      checked={getOjectValue(item.id) === subItem.value}
                      onChange={() => {
                        setFormData({ ...formData, [item.id]: subItem.value })
                        setCourseAppForm({
                          ...courseAppForm,
                          idCardNumber: '',
                          nrcDigit: '',
                          nrcType: '',
                          nrcNumber: '',
                          nrcArea: '',
                        })
                      }}
                    />
                  ))
                )}
              </div>
            )
        )}
      </div>
    </>
  )
}
export default NRCPassport
