import { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Lang, PageBanner } from "../../../components";
import { SearchInput } from "../../../components/common/CustomInput";
import {
  IconCalender,
  IconGeo,
  IconPhone,
} from "../../../components/common/Icons";
import { GridLoading } from "../../../components/common/Loading";
import { PgComponent } from "../../../components/common/Pagination";
import { CourseAppFormContext } from "../../../context/Form";
import { LangContext } from "../../../context/Lang";
import { PageContext } from "../../../context/Page";
import { VariableContext } from "../../../context/Variable";
import { handleShow } from "../../../services";
import { getRequest } from "../../../services/fetch";
import CenterHeader from "./Header";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT;

interface Target {
  id?: number;
  itemName?: string;
  image?: string;
}

const GridLayout = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [variable, setVariable] = useContext(VariableContext);
  const [page, setPage] = useContext(PageContext);
  const [courseAppForm, setCourseAppForm] = useContext(CourseAppFormContext);
  const [, setTarget] = useState<Target>();
  const [, setShow] = useState(false);
  const {
    state: { language },
  } = useContext(LangContext);
  useEffect(() => {
    setVariable({ ...variable, activeType: "grid" });
    handleGetData(page.current);
    localStorage.getItem("RegisterData") &&
      localStorage.removeItem("RegisterData");
    // eslint-disable-next-line
  }, []);

  const handleGetData = async (skip: number) => {
    setLoading(true);
    await getRequest({
      route: `centers/${skip * limit}/${limit}`,
      isPrivate: false,
    }).then((result) => {
      setData(result.data);
      setPage({
        current: skip,
        total: result.count,
      });
      setInterval(() => {
        setLoading(false);
      }, 1000);
    });
  };

  const thumbsnailImage = (item: any) => {
    let tempImages = item.centerImages;
    let isExist = false;
    let tempDiv = (
      <img
        src="images/IDGVA_Logo.png"
        alt="Thumbnail"
        className="float-right inv-thumb"
      />
    );

    if (tempImages && tempImages.length > 0) {
      tempImages.forEach((img: any) => {
        if (img.thumbnail) {
          isExist = true;

          tempDiv = (
            <img
              src={`${RES_API_URL}${img.url}`}
              alt="no img"
              className="float-right inv-thumb cursor"
              onClick={() => {
                setTarget({ itemName: item?.itemName, image: img.url });
                handleShow(setShow);
              }}
            />
          );
        }
      });

      if (!isExist) {
        tempDiv = (
          <img
            src={`${RES_API_URL}${tempImages[0].url}`}
            alt="no img"
            className="float-right inv-thumb cursor"
            onClick={() => {
              setTarget({ itemName: item?.itemName, image: tempImages[0].url });
              handleShow(setShow);
            }}
          />
        );
      }
    }
    return tempDiv;
  };

  const goDetail = (item: any) => {
    localStorage.setItem(
      "RegisterData",
      JSON.stringify({
        centerinfo: item,
        scheduleInfo: {},
        answeredData: {},
      })
    );
    setCourseAppForm({ ...courseAppForm, center: item });
    navigate("/center-detail", { state: { id: item.id } });
  };
  const handleGetSearchData = (skip: number, searchString: string) => {
    setLoading(true);
    getRequest({
      route: `centers/${skip * limit}/${limit}?keyword=${searchString}`,
      isPrivate: false,
    }).then((result) => {
      setData(result.data);
      setPage &&
        setPage({
          current: skip,
          total: result.count,
        });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };

  const handleSerachEnterAction = (e: any) => {
    if (e.keyCode === 13) {
      if (e.target.value) {
        handleGetSearchData(0, e.target.value);
      } else {
        handleGetData(0);
        setSearchString("");
      }
    }
  };
  const handleSerachAction = () => {
    handleGetSearchData(0, searchString);
  };
  return (
    <>
      <PageBanner
        title={<Lang label="centers" />}
        label={<Lang label="headerDescription" />}
      />
      <Container className="mt-5">
        <div className="d-flex mb-4">
          <CenterHeader />
          <Col className="d-flex flex-end flex-center">
            <span className="text-blue me-2  d-none d-md-inline-block">
              <Lang label="centerTotal" />: {page.total}
            </span>
            <SearchInput
              handleSerachEnterAction={handleSerachEnterAction}
              handleSerachAction={handleSerachAction}
              searchString={searchString}
              setSearchString={setSearchString}
              handleClearSearch={() => {
                handleGetData(0);
                setSearchString("");
              }}
            />
          </Col>
        </div>
        {loading ? (
          <GridLoading />
        ) : (
          <Row className="">
            {data.map((item: any, index: number) => (
              <Col lg={4} md={6} sm={12} className="mb-4" key={index}>
                <div className="bg-card ">
                  <div
                    className="event-img cursor"
                    onClick={() => goDetail(item)}
                  >
                    {thumbsnailImage(item)}
                  </div>
                  <Row className="p-4 d-box">
                    <Col md={12}>
                      <span
                        className="fw-bold fs-5"
                        onClick={() => goDetail(item)}
                      >
                        {language === "EN"
                          ? item.centerNameEN
                          : item.centerNameMM}
                      </span>
                      <div className=" align-items-center justify-content-start mt-3">
                        <div className="me-1 mb-2">
                          <IconCalender size={16} color="blue" />{" "}
                          <small
                            className="ms-1 cursor text-blue hover"
                            onClick={() => goDetail(item)}
                          >
                            <Lang label="coursess" />
                          </small>
                        </div>
                        <div className="me-1 mb-2">
                          <IconGeo size={16} color="black" />{" "}
                          <small className="ms-1 ">
                            {language === "EN"
                              ? item.addressEN
                              : item.addressMM}
                          </small>
                        </div>
                        <span>
                          <IconPhone size={16} color="black" />{" "}
                          <small className="ms-1  ">
                            {item.phone} {item.phoneOther}
                          </small>
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        )}
      </Container>
      {page && page.total > limit && (
        <PgComponent
          currentPage={page.current + 1 || 0}
          fromCount={page.current || 0}
          total={page.total || 0}
          paginationLimit={limit}
          pageInfo={page}
          setPage={setPage}
          handleGetData={handleGetData}
        />
      )}
    </>
  );
};
export default GridLayout;
