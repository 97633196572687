import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Lang } from "../../../components";
import { IconCircle } from "../../../components/common/Icons";
import { DetailLoading } from "../../../components/common/Loading";
import TableLayout from "../../../components/layout/TableLayout";
import { LangContext } from "../../../context/Lang";
import { dateFormat3, handleDateFormat } from "../../../services";
import { getRequest } from "../../../services/fetch";

const FeatureCenters = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [courseSchedule, setCourseSchedule] = useState([]);
  const location: any = useLocation();
  const id = location.state.id;
  const {
    state: { language },
  } = useContext(LangContext);
  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = async (skip?: number) => {
    setLoading(true);
    setInterval(() => {
      setLoading(false);
    }, 1500);
    await getRequest({
      route: `schedules/center/${id}`,
      isPrivate: false,
    }).then((result) => {
      let temp: any = [];
      result.data.courseTitles?.forEach((item: any) => {
        temp.push({ key: item.courseTitleId });
      });
      setCourseSchedule(result.data.courseTitles);
      setInterval(() => {
        setLoading(false);
      }, 1000);
    });
  };

  const checkStatus = (coursesAvailability: any) => {
    let isExist = false;
    coursesAvailability &&
      coursesAvailability?.length > 0 &&
      coursesAvailability?.forEach((item: any) => {
        if (
          item.formStatus === "Open" ||
          item.formStatus === "Short Wait List"
        ) {
          isExist = true;
        }
      });
    return isExist;
  };

  return (
    <>
      {loading ? (
        <DetailLoading />
      ) : (
        <Container className="my-3">
          <div>
            {courseSchedule?.length === 0 ? (
              <>
                <div className="fw-bold my-5 text-blue">
                  <h5> No courses are currently scheduled.</h5>
                </div>
              </>
            ) : (
              <>
                {courseSchedule?.length > 0 &&
                (courseSchedule as any[]).some((subItem) =>
                  (subItem?.schedules as any[]).some(
                    (subItem2) =>
                      new Date(subItem2.startDate).getFullYear() ===
                      new Date().getFullYear()
                  )
                )
                  ? courseSchedule?.map((subItem: any, subIndex: number) => (
                      <div className="bg-card-no-hover mb-5" key={subIndex}>
                        <div className="bg-title p-4 f-col">
                          <h5 className="text-blue">
                            {language === "EN"
                              ? subItem.titleName
                              : subItem.titleNameMM}
                          </h5>
                          <span className="my-2">
                            {language === "EN"
                              ? subItem.titleDescription
                              : subItem.titleDescriptionMM}
                          </span>
                        </div>
                        <div className="d-md-none d-sm-block ">
                          <div className="f-col p-2 text-muted border-bottom border-top bg-light ">
                            {subItem?.schedules &&
                              subItem?.schedules.length > 0 &&
                              subItem?.schedules
                                .filter((subItem2: any) => {
                                  // Filter schedules for the year 2024
                                  const scheduleYear = new Date(
                                    subItem2.startDate
                                  ).getFullYear();
                                  return (
                                    scheduleYear === new Date().getFullYear()
                                  );
                                })
                                .map((subItem2: any, subIndex2: number) => (
                                  <Row
                                    className="schedule-card m-2 p-2"
                                    key={subIndex2}
                                  >
                                    <Col sm={6}>
                                      <div className="f-col">
                                        <span className="cursor text-blue underline fw-bold mb-2">
                                          <>
                                            {
                                              <>
                                                {subItem2.formStatus ===
                                                "In Progress" ? (
                                                  <span className="left-align fw-bold ms-3">
                                                    In Progress
                                                  </span>
                                                ) : subItem2.formStatus ===
                                                  "Course full" ? (
                                                  <span className="left-align fw-bold ms-3">
                                                    {/* Course full */}
                                                  </span>
                                                ) : subItem2.formStatus ===
                                                  "Completed" ? (
                                                  <span className="left-align fw-bold ms-3"></span>
                                                ) : (
                                                  subItem.titleName !==
                                                    "Teenagers' and Children's Courses" && (
                                                    <>
                                                      {checkStatus(
                                                        subItem2.availability
                                                      ) && (
                                                        <span
                                                          className="cursor underlined fw-bold text-blue"
                                                          onClick={() => {
                                                            navigate("/cod", {
                                                              state: {
                                                                item: subItem2.availability,
                                                              },
                                                            });
                                                            const temp: any =
                                                              localStorage.getItem(
                                                                "RegisterData"
                                                              );
                                                            const tempData =
                                                              temp &&
                                                              JSON.parse(temp);
                                                            tempData.scheduleInfo =
                                                              subItem2;
                                                            localStorage.setItem(
                                                              "RegisterData",
                                                              JSON.stringify(
                                                                tempData
                                                              )
                                                            );
                                                          }}
                                                        >
                                                          <Lang label="apply" />
                                                        </span>
                                                      )}
                                                    </>
                                                  )
                                                )}
                                              </>
                                            }
                                          </>
                                        </span>
                                        <small>
                                          {handleDateFormat(
                                            subItem2.startDate,
                                            dateFormat3
                                          )}
                                          {" - "}
                                          {handleDateFormat(
                                            subItem2.endDate,
                                            dateFormat3
                                          )}
                                        </small>
                                        <span className="mb-1">
                                          <span className="">
                                            <Lang label="courseType" />:
                                          </span>
                                          {language === "EN"
                                            ? subItem2.courses.courseType
                                            : subItem2.courses.courseTypeMM}
                                        </span>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="f-col">
                                        <span className="fw-bold my-1">
                                          {subItem2.availability && (
                                            <Lang label="status" />
                                          )}
                                        </span>
                                        <span>
                                          {subItem2.formStatus !== "Open" ? (
                                            <span className="fw-bold">
                                              {subItem2.formStatus}
                                            </span>
                                          ) : subItem2.formStatus ===
                                            "In Progress" ? (
                                            <span className="left-align fw-bold">
                                              "In Progress"
                                            </span>
                                          ) : (
                                            <>
                                              {subItem2.availability &&
                                                subItem2.availability.length >
                                                  0 &&
                                                subItem2.availability.map(
                                                  (
                                                    subItem3: any,
                                                    subIndex3: number
                                                  ) => (
                                                    <div
                                                      key={subIndex3}
                                                      className="d-flex align-items-center mb-1"
                                                    >
                                                      <IconCircle
                                                        size={10}
                                                        color={
                                                          subItem3.formStatus ===
                                                          "Open"
                                                            ? "green"
                                                            : subItem3.formStatus ===
                                                              "Closed"
                                                            ? "red"
                                                            : subItem3.formStatus ===
                                                              "Course Full"
                                                            ? "red"
                                                            : subItem3.formStatus ===
                                                              "Short Wait List"
                                                            ? "yellow"
                                                            : "white"
                                                        }
                                                      />

                                                      <small className="ms-2">
                                                        {subItem3.attendeeType}{" "}
                                                        {subItem3.courseFor}{" "}
                                                        {subItem3.applicantType !==
                                                          "Student" &&
                                                          subItem3.applicantType}
                                                        {"-"}
                                                        {subItem3.formStatus}
                                                      </small>
                                                    </div>
                                                  )
                                                )}
                                            </>
                                          )}
                                        </span>
                                      </div>
                                    </Col>
                                    <Col sm={12}>
                                      <small className="my-2">
                                        {subItem2.comments}
                                      </small>
                                    </Col>
                                  </Row>
                                ))}
                          </div>
                        </div>
                        <div className="d-none d-md-block ">
                          {subItem?.schedules &&
                            subItem?.schedules.length > 0 &&
                            subItem?.schedules.filter((subItem2: any) => {
                              const scheduleYear = new Date(
                                subItem2.startDate
                              ).getFullYear();
                              return scheduleYear === new Date().getFullYear();
                            }).length > 0 && (
                              <TableLayout loading={loading} data={""}>
                                <thead>
                                  <tr>
                                    <th>
                                      <span className="align-left ms-3">
                                        <Lang label="attend" />
                                      </span>
                                    </th>
                                    <th>
                                      <span className="align-left">
                                        <Lang label="dates" />
                                      </span>
                                    </th>
                                    <th>
                                      <span className="align-left">
                                        <Lang label="courseType" />
                                      </span>
                                    </th>
                                    <th>
                                      <span className="align-left">
                                        <Lang label="status" />
                                      </span>
                                    </th>
                                    <th>
                                      <span className="align-left">
                                        <Lang label="comment" />
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {subItem?.schedules &&
                                    subItem?.schedules.length > 0 &&
                                    subItem?.schedules
                                      .filter((subItem2: any) => {
                                        const scheduleYear = new Date(
                                          subItem2.startDate
                                        ).getFullYear();
                                        return (
                                          scheduleYear ===
                                          new Date().getFullYear()
                                        );
                                      })
                                      .map(
                                        (subItem2: any, subIndex2: number) => (
                                          <tr key={subIndex2}>
                                            <td className="">
                                              <>
                                                {
                                                  <>
                                                    {subItem2.formStatus ===
                                                    "In Progress" ? (
                                                      <span className="left-align fw-bold ms-3">
                                                        In Progress
                                                      </span>
                                                    ) : subItem2.formStatus ===
                                                      "Course full" ? (
                                                      <span className="left-align fw-bold ms-3"></span>
                                                    ) : subItem2.formStatus ===
                                                      "Completed" ? (
                                                      <span className="left-align fw-bold ms-3"></span>
                                                    ) : (
                                                      subItem?.titleName !==
                                                        "Teenagers' and Children's Courses" && (
                                                        <>
                                                          {checkStatus(
                                                            subItem2.availability
                                                          ) && (
                                                            <span
                                                              className="cursor underlined fw-bold"
                                                              onClick={() => {
                                                                navigate(
                                                                  "/cod",
                                                                  {
                                                                    state: {
                                                                      item: subItem2,
                                                                    },
                                                                  }
                                                                );
                                                                const temp: any =
                                                                  localStorage.getItem(
                                                                    "RegisterData"
                                                                  );
                                                                const tempData =
                                                                  temp &&
                                                                  JSON.parse(
                                                                    temp
                                                                  );
                                                                tempData.scheduleInfo =
                                                                  subItem2;
                                                                localStorage.setItem(
                                                                  "RegisterData",
                                                                  JSON.stringify(
                                                                    tempData
                                                                  )
                                                                );
                                                              }}
                                                            >
                                                              <span className="align-left text-blue ms-3">
                                                                <Lang label="apply" />
                                                              </span>
                                                            </span>
                                                          )}
                                                        </>
                                                      )
                                                    )}
                                                  </>
                                                }
                                              </>
                                            </td>
                                            <td>
                                              <span className="align-left">
                                                {handleDateFormat(
                                                  subItem2.startDate,
                                                  dateFormat3
                                                )}
                                                <span> - </span>

                                                {handleDateFormat(
                                                  subItem2.endDate,
                                                  dateFormat3
                                                )}
                                              </span>
                                            </td>
                                            <td>
                                              <span className="align-left">
                                                {language === "EN"
                                                  ? subItem2.courses.courseType
                                                  : subItem2.courses
                                                      .courseTypeMM}
                                              </span>
                                            </td>
                                            <td>
                                              {subItem2.formStatus !== "Open" &&
                                              subItem2.formStatus !==
                                                "In Progress" ? (
                                                subItem2.formStatus
                                              ) : subItem2.formStatus ===
                                                "In Progress" ? (
                                                <> </>
                                              ) : (
                                                <>
                                                  {subItem2.availability &&
                                                    subItem2.availability
                                                      .length > 0 &&
                                                    subItem2.availability.map(
                                                      (
                                                        subItem3: any,
                                                        subIndex3: number
                                                      ) => (
                                                        <div
                                                          key={subIndex3}
                                                          className="d-flex align-items-center mb-2"
                                                        >
                                                          <IconCircle
                                                            size={12}
                                                            color={
                                                              subItem3.formStatus ===
                                                              "Open"
                                                                ? "green"
                                                                : subItem3.formStatus ===
                                                                  "Closed"
                                                                ? "red"
                                                                : subItem3.formStatus ===
                                                                  "Course Full"
                                                                ? "red"
                                                                : subItem.formStatus ===
                                                                  "Short Wait List"
                                                                ? "yellow"
                                                                : "white"
                                                            }
                                                          />
                                                          <span className="ms-2">
                                                            {
                                                              subItem3.attendeeType
                                                            }{" "}
                                                            {subItem3.courseFor}{" "}
                                                            {subItem3.applicantType !==
                                                              "Student" &&
                                                              subItem3.applicantType}{" "}
                                                            {"-"}{" "}
                                                            {
                                                              subItem3.formStatus
                                                            }
                                                          </span>
                                                        </div>
                                                      )
                                                    )}
                                                </>
                                              )}
                                            </td>
                                            <td className="align-left">
                                              {subItem2.comments}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                </tbody>
                              </TableLayout>
                            )}
                        </div>
                      </div>
                    ))
                  : null}
              </>
            )}
          </div>
        </Container>
      )}
    </>
  );
};
export default FeatureCenters;
