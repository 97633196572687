import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import Context from "./context";

const container: any = document.getElementById("root");
const root = createRoot(container);
// root.render(
//   <React.StrictMode>
//     <Context>
//       <App />
//     </Context>

//   </React.StrictMode>
// );
root.render(
  <Context>
    <App />
  </Context>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
