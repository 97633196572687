import { Lang, PageBanner } from "../../../components";
import Layout from "../../../components/layout/Layout";
import CenterDetailCard from "./CenterDetailCard";
import FeatureCenters from "./FeatureCenters";

const CenterDetail = () => {
  return (
    <>
      <Layout>
        <PageBanner
          title={<Lang label="centers" />}
          label={<Lang label="headerDescription" />}
        />
        <CenterDetailCard />
        <FeatureCenters />
      </Layout>
    </>
  );
};
export default CenterDetail;
