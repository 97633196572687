import { useContext, useEffect, useState } from "react";
import { Container, Image } from "react-bootstrap";
import { Lang } from "../../../components";
import { DetailLoading } from "../../../components/common/Loading";
import { LangContext } from "../../../context/Lang";
import { getRequest } from "../../../services/fetch";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

const Content = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>("");
  const {
    state: { language },
  } = useContext(LangContext);
  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);
  const handleGetData = async () => {
    setLoading(true);
    getRequest({ route: "main", isPrivate: false }).then((result) => {
      setData(result.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };

  return (
    <>
      {loading ? (
        <DetailLoading />
      ) : (
        <>
          <div className="banner ">
            <Image src={`${RES_API_URL}${data.url}`} />
          </div>
          <Container className="mb-5">
            <h2 className="my-4 text-left text-blue text-center">
              <Lang label="title" />
            </h2>
            <h4 className="my-4 text-left ">
              {language === "EN" ? data.introductionEN : data.introductionMM}
            </h4>
            <p className="lh-2 mt-2" style={{ whiteSpace: "pre-line" }}>
              {language === "EN" ? data.descriptionEN : data.descriptionMM}
            </p>
          </Container>
        </>
      )}
    </>
  );
};
export default Content;
