import { useContext } from 'react'
import { Container, Form } from 'react-bootstrap'
import { FieldAccorion, Lang } from '../../../../components'
import { CourseAppFormContext } from '../../../../context/Form'

const HealthDetail = () => {
  const [courseAppForm, setCourseAppForm] = useContext(CourseAppFormContext)
  const fields = [
    {
      id: 'languagesSkill',
      title: <Lang label="q_2" />,
      detail: 'd',
      show: true,
      required: true,
    },
    {
      id: 'practiceKnownFrom',
      title: <Lang label="q_3" />,
      description: '',
      detail: ' gsa',
      show: true,
      required: true,
    },
  ]
  return (
    <>
      <Container>
        <div className="pt-3">
          <FieldAccorion label={<Lang label="q_1" />}>
            <Form.Check
              inline
              label={<Lang label="yes" />}
              type={'radio'}
              id={'isYourSpouseAttendeeYes'}
              name="isYourSpouseAttendee"
              checked={courseAppForm.isYourSpouseAttendee}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isYourSpouseAttendee: true,
                })
              }
            />
            <Form.Check
              inline
              label={<Lang label="no" />}
              name="isYourSpouseAttendee"
              type={'radio'}
              id={`isYourSpouseAttendeeNo`}
              checked={!courseAppForm.isYourSpouseAttendee}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isYourSpouseAttendee: false,
                })
              }
            />
          </FieldAccorion>
          {fields.map(
            (item: any, index: number) =>
              item.show && (
                <FieldAccorion label={item.title} key={index}>
                  {item.detail && (
                    <>
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          required={item.required}
                          as="textarea"
                          rows={3}
                          value={courseAppForm[item.id]}
                          onChange={(e) =>
                            setCourseAppForm({
                              ...courseAppForm,
                              [item.id]: e.target.value,
                            })
                          }
                        ></Form.Control>
                      </Form.Group>
                    </>
                  )}
                </FieldAccorion>
              )
          )}
          <FieldAccorion label={<Lang label="q_5" />}>
            <Form.Check
              inline
              label={<Lang label="yes" />}
              type={'radio'}
              id={'illnessMentalYes'}
              name="illnessMentalYes"
              checked={courseAppForm.isIllnessMental}
              onChange={() =>
                setCourseAppForm({ ...courseAppForm, isIllnessMental: true })
              }
            />
            <Form.Check
              inline
              label={<Lang label="no" />}
              name="illnessMentalNo"
              type={'radio'}
              id={`illnessMentalNo`}
              checked={!courseAppForm.isIllnessMental}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isIllnessMental: false,
                  IllnessMentalDetail: '',
                })
              }
            />
          </FieldAccorion>
          {courseAppForm.isIllnessMental && (
            <FieldAccorion label={<Lang label="detail_fill" />}>
              <Form.Control
                as="textarea"
                rows={3}
                value={courseAppForm.IllnessMentalDetail}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    IllnessMentalDetail: e.target.value,
                  })
                }
              />
            </FieldAccorion>
          )}
          {/* //isIllenssDetail */}
          <FieldAccorion label={<Lang label="q_4" />}>
            <Form.Check
              inline
              label={<Lang label="yes" />}
              type={'radio'}
              id={'illnessPhysicalYes'}
              name="illnessPhysicalYes"
              checked={courseAppForm.isExistPhysicalIllness}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isExistPhysicalIllness: true,
                })
              }
            />
            <Form.Check
              inline
              label={<Lang label="no" />}
              name="illnessPhysicalYes"
              type={'radio'}
              id={`illnessPhysicalNo`}
              checked={!courseAppForm.isExistPhysicalIllness}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isExistPhysicalIllness: false,
                  physicalIllnessDetail: '',
                })
              }
            />
          </FieldAccorion>
          {courseAppForm.isExistPhysicalIllness && (
            <FieldAccorion label={<Lang label="detail_fill" />}>
              <Form.Control
                as="textarea"
                rows={3}
                value={courseAppForm.physicalIllnessDetail}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    physicalIllnessDetail: e.target.value,
                  })
                }
              />
            </FieldAccorion>
          )}
          <FieldAccorion label={<Lang label="q_6" />}>
            <Form.Check
              inline
              label={<Lang label="yes" />}
              type={'radio'}
              id={'practicedMeditationTechniquesYes'}
              name="practicedMeditationTechniques"
              checked={courseAppForm.isPracticeOtherTeqInPast}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isPracticeOtherTeqInPast: true,
                })
              }
            />
            <Form.Check
              inline
              label={<Lang label="no" />}
              name="practicedMeditationTechniques"
              type={'radio'}
              id={`practicedMeditationTechniquesYesNO`}
              checked={!courseAppForm.isPracticeOtherTeqInPast}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isPracticeOtherTeqInPast: false,
                  otherTeqInPastDetail: '',
                })
              }
            />
          </FieldAccorion>
          {courseAppForm.isPracticeOtherTeqInPast && (
            <FieldAccorion label={<Lang label="detail_fill" />}>
              <Form.Control
                as="textarea"
                rows={3}
                value={courseAppForm.otherTeqInPastDetail}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    otherTeqInPastDetail: e.target.value,
                  })
                }
              />
            </FieldAccorion>
          )}
          <FieldAccorion label={<Lang label="q_7" />}>
            <Form.Check
              inline
              label={<Lang label="yes" />}
              type={'radio'}
              id={'addictedDrugsAlcoholDetailYes'}
              name="addictedDrugsAlcohol"
              checked={courseAppForm.isAddictedDrugsAlcohol}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isAddictedDrugsAlcohol: true,
                })
              }
            />
            <Form.Check
              inline
              label={<Lang label="no" />}
              name="addictedDrugsAlcohol"
              type={'radio'}
              id={`addictedDrugsAlcoholDetailNo`}
              checked={!courseAppForm.isAddictedDrugsAlcohol}
              onChange={() =>
                setCourseAppForm({
                  ...courseAppForm,
                  isAddictedDrugsAlcohol: false,
                  addictedDrugsAlcoholDetail: '',
                })
              }
            />
          </FieldAccorion>
          {courseAppForm.isAddictedDrugsAlcohol && (
            <FieldAccorion label={<Lang label="detail_fill" />}>
              <Form.Control
                as="textarea"
                rows={3}
                value={courseAppForm.addictedDrugsAlcoholDetail}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    addictedDrugsAlcoholDetail: e.target.value,
                  })
                }
              />
            </FieldAccorion>
          )}
        </div>
      </Container>
    </>
  )
}
export default HealthDetail
