import { useContext, useEffect, useState } from "react";
import { Accordion, Button, Container, Image, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { LangContext } from "../context/Lang";
import { IconUp } from "./common/Icons";

export function ScrollToTopPage() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);
  return null;
}
export const NoData = ({
  title = "data",
  noImage = false,
}: {
  title?: string;
  noImage?: boolean;
}) => {
  return (
    <section className="d-flex align-items-center flex-column my-4">
      {!noImage && <Image src={"images/no_data.png"} width={200} />}
      <p className="fs-6 mb-0 text-success">No {title} found!</p>
    </section>
  );
};
export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <div className="scroll-to-top">
      {isVisible && (
        <div onClick={scrollToTop} className="d-inline float-end scroll-to-top">
          <Button variant="link" className="btn-rounded-sm shadow">
            <IconUp />
          </Button>
        </div>
      )}
    </div>
  );
}

export const Lang = ({ label }: { label: string }) => {
  const {
    dispatch: { translate },
  } = useContext(LangContext);
  return <>{translate(label)}</>;
};

export const PageBanner = ({ title, label }: { title: any; label: any }) => {
  return (
    <>
      <Container fluid className="bg-light ">
        <div className="flex-column  py-5">
          <h2 className="mb-3 d-flex flex-center ">{title}</h2>
          <div className="text-center">
            <span className="text-blue flex-center">{label}</span>
          </div>
        </div>
      </Container>
    </>
  );
};
export const FieldAccorion = ({
  label,
  children,
}: {
  label: any;
  children: any;
}) => {
  return (
    <Accordion className="mb-4" defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header className="">{label}</Accordion.Header>
        <Accordion.Body>
          <Row>{children}</Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
