import { Lang } from '../components'

export const Cod = [
  { title: <Lang label="content_4" />, label: <Lang label="content_4do" /> },
  { title: <Lang label="content_5" />, label: <Lang label="content_5do" /> },
  { title: <Lang label="content_6" />, label: <Lang label="content_6do" /> },
  { title: <Lang label="content_7" />, label: <Lang label="content_7do" /> },
  { title: <Lang label="content_8" />, label: <Lang label="content_8do" /> },
  { title: <Lang label="content_9" />, label: <Lang label="content_9do" /> },
  { title: <Lang label="content_10" />, label: <Lang label="content_10do" /> },
  { title: <Lang label="content_11" />, label: <Lang label="content_11do" /> },
  { title: <Lang label="content_12" />, label: <Lang label="content_12do" /> },
  { title: <Lang label="content_13" />, label: <Lang label="content_13do" /> },
  { title: <Lang label="content_14" />, label: <Lang label="content_14do" /> },
  { title: <Lang label="content_15" />, label: <Lang label="content_15do" /> },
  { title: <Lang label="content_16" />, label: <Lang label="content_16do" /> },
  { title: <Lang label="content_17" />, label: <Lang label="content_17do" /> },
  { title: <Lang label="content_18" />, label: <Lang label="content_18do" /> },
  { title: <Lang label="content_19" />, label: <Lang label="content_19do" /> },
  { title: <Lang label="content_20" />, label: <Lang label="content_20do" /> },
  { title: <Lang label="content_21" />, label: <Lang label="content_21do" /> },
  { title: <Lang label="content_22" />, label: <Lang label="content_22do" /> },
  { title: <Lang label="timeTable" />, label: <Lang label="timeTable_dscp" /> },
]

export const timetable = [
  { time: <Lang label="period1" />, activity: <Lang label="period_do1" /> },
  { time: <Lang label="period2" />, activity: <Lang label="period_do2" /> },
  { time: <Lang label="period3" />, activity: <Lang label="period_do3" /> },
  { time: <Lang label="period4" />, activity: <Lang label="period_do4" /> },
  { time: <Lang label="period5" />, activity: <Lang label="period_do5" /> },
  { time: <Lang label="period6" />, activity: <Lang label="period_do6" /> },
  { time: <Lang label="period7" />, activity: <Lang label="period_do7" /> },
  { time: <Lang label="period8" />, activity: <Lang label="period_do8" /> },
  { time: <Lang label="period9" />, activity: <Lang label="period_do9" /> },
  { time: <Lang label="period10" />, activity: <Lang label="period_do10" /> },
  { time: <Lang label="period11" />, activity: <Lang label="period_do11" /> },
  { time: <Lang label="period12" />, activity: <Lang label="period_do12" /> },
  { time: <Lang label="period13" />, activity: <Lang label="period_do13" /> },
  { time: <Lang label="period14" />, activity: <Lang label="period_do14" /> },
  { time: <Lang label="period15" />, activity: <Lang label="period_do15" /> },
  { time: <Lang label="period16" />, activity: <Lang label="period_do16" /> },
  { time: <Lang label="period17" />, activity: <Lang label="period_do17" /> },
  { time: <Lang label="period18" />, activity: <Lang label="period_do18" /> },
]
export const privacy = [
  {
    title: <Lang label="privacy1" />,
    description: <Lang label="privacyContent1" />,
  },
  {
    title: <Lang label="privacy2" />,
    description: <Lang label="privacyContent2" />,
  },
  {
    title: <Lang label="privacy3" />,
    description: <Lang label="privacyContent3" />,
  },
  {
    title: <Lang label="privacy4" />,
    description: <Lang label="privacyContent4" />,
  },
]
