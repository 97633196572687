import { useContext, useEffect, useState } from 'react'
import {
  Button,
  ButtonGroup,
  OverlayTrigger,
  Row,
  Tooltip,
  Col,
} from 'react-bootstrap'
import { IconGrid, IconList, IconMap } from '../../../components/common/Icons'
import { PageContext } from '../../../context/Page'
import { VariableContext } from '../../../context/Variable'
import { getRequest } from '../../../services/fetch'
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT

const CenterHeader = () => {
  const [variable, setVariable] = useContext(VariableContext)
  const [, setData] = useState([])
  const [page, setPage] = useContext(PageContext)

  const [, setLoading] = useState(false)
  useEffect(() => {
    handleGetData(page.current)
    // eslint-disable-next-line
  }, [])

  const handleGetData = async (skip: number) => {
    setLoading(true)
    await getRequest({
      route: `centers/${skip * limit}/${limit}`,
      isPrivate: false,
    }).then((result) => {
      setData(result.data)
      setPage({
        current: skip,
        total: result.count,
      })
      setInterval(() => {
        setLoading(false)
      }, 1000)
    })
  }
  return (
    <>
      <Row className="">
        <Col className="col-lg-3 col-md-0 ">
          <ButtonGroup>
            <OverlayTrigger overlay={<Tooltip>Grid view</Tooltip>}>
              <Button
                onClick={() => setVariable({ ...variable, activeType: 'grid' })}
                className={
                  variable?.activeType === 'grid' ||
                  variable?.activeType === null
                    ? 'text-white bg-grad btn-yel-right'
                    : 'bg-white text-primary btn-yel-left '
                }
              >
                <IconGrid />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>List view</Tooltip>}>
              <Button
                onClick={() => setVariable({ ...variable, activeType: 'list' })}
                className={
                  variable?.activeType === 'list'
                    ? 'text-white bg-grad btn-yel-right'
                    : 'bg-white text-primary btn-yel-left '
                }
              >
                <IconList />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Map view</Tooltip>}>
              <Button
                onClick={() => setVariable({ ...variable, activeType: 'map' })}
                className={
                  variable?.activeType === 'map'
                    ? 'text-white bg-grad btn-yel-right'
                    : 'bg-white text-primary btn-yel-left '
                }
              >
                <IconMap />
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
        </Col>
      </Row>
    </>
  )
}
export default CenterHeader
