import Layout from "../../components/layout/Layout";
import Content from "./cpn/Content";

const Home = () => {
  return (
    <>
      <Layout>
        <Content />
      </Layout>
    </>
  );
};
export default Home;
