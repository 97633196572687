import { useState, useEffect, useContext } from "react";
import { Button, Container, Form, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Lang, PageBanner } from "../../../components";
import Layout from "../../../components/layout/Layout";
import { LangContext } from "../../../context/Lang";
import { Cod, privacy, timetable } from "../../../data/tempData";

const IntroTechnique = () => {
  const [checked, setChecked] = useState(false);
  const [selectData, setSelectData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const history = useNavigate();
  const temp: any = localStorage.getItem("RegisterData");
  const {
    state: { language },
  } = useContext(LangContext);
  const tempData = temp && JSON.parse(temp);
  localStorage.setItem("RegisterData", JSON.stringify(tempData));
  const addData: any = {
    gender: "Female",
    fullName: "",
    isForeigner: false,
    fatherName: "",
    dateOfBirth: "",
    age: "",
    nationality: "",
    idType: "",
    idCardNumber: "",
    phoneNumber: "",
    education: "",
    occupation: "",
    email: "",
    country: "",
    state: "",
    city: "",
    completeAddress: "",
    termsReadDiscipline: false,
    termsAcceptDiscipline: false,
    termsDenyToLeave: false,
    termsSilent: false,
    termsNotReligious: false,
    termsRefrainAlcoholDrugs: false,
    termsAgree: false,
    emergencyContact: "",
    relativeAttendeeInfo: "",
    isYourSpouseAttendee: false,
    languagesSkill: "",
    practiceKnownFrom: "",
    isExistMentalIllness: false,
    mentalIllnessDetail: "",
    isExistPhysicalIllness: false,
    physicalIllnessDetail: "",
    isPracticeOtherTeqInPast: false,
    otherTeqInPastDetail: "",
    isAddictedDrugsAlcohol: false,
    addictedDrugsAlcoholDetail: "",
    familyRelationship: "",
    aboutMindState: "",
    severeDepression: "",
    purposeOfEntrance: "",
    strictlyFollowTheRules: false,
    digitalSignature: "",
  };
  const OldStudentAddData: any = {
    gender: "Female",
    isForeigner: false,

    fullName: "",
    fatherName: "",
    dateOfBirth: "",
    age: "",
    nationality: "",
    idType: "",
    idCardNumber: "",
    phoneNumber: "",
    education: "",
    occupation: "",
    email: "",
    country: "",
    state: "",
    city: "",
    termsReadDiscipline: false,
    termsAcceptDiscipline: false,
    termsDenyToLeave: false,
    termsSilent: false,
    termsNotReligious: false,
    termsRefrainAlcoholDrugs: false,
    willingToVolunteer: false,
    termsAgree: false,
    emergencyContact: "",
    relativeAttendeeInfo: "",
    firstCourseDate: "",
    firstCourseTeacher: "",
    firstCourseCenterName: "",
    firstCourseCenterTownship: "",
    recentCourseDate: "",
    recentCourseTeacher: "",
    recentCourseCenterName: "",
    recentCourseCenterTownship: "",
    isYourSpouseAttendee: false,
    languagesSkill: "",
    isMaintainPractice: false,
    maintainPracticeDetail: " ",
    isPracticeOtherMeditaitonTeq: false,
    otherMeditaitonTeqDetail: "",
    isExistMentalIllness: false,
    mentalIllnessDetail: "",
    isExistPhysicalIllness: false,
    physicalIllnessDetail: "",
    isPracticeOtherTeqInPast: false,
    otherTeqInPastDetail: "",
    isAddictedDrugsAlcohol: false,
    addictedDrugsAlcoholDetail: "",
    noticeChangesInHealth: "",
    noticechangesInCommunication: "",
    noticeChangesInAddiction: "",
    autoBiography: "",
    strictlyFollowTheRules: false,
    digitalSignature: "",
  };
  const ServerAddData: any = {
    gender: "Female",
    isForeigner: false,

    fullName: "",
    fatherName: "",
    dateOfBirth: "",
    age: "",
    nationality: "",
    idType: "",
    idCardNumber: "",
    phoneNumber: "",
    education: "",
    occupation: "",
    email: "",
    country: "",
    state: "",
    city: "",
    completeAddress: "",
    isReadServerDisciplines: false,
    lastServedCourseDate: "",
    lastServedDhammaCenter: "",
    lastServedDhammaTeacher: "",
    numOfTimeAttend: "",
    lastAttendedCourseDate: "",
    lastAttendedDhammaCenter: "",
    lastAttendedDhammaTeacher: "",
    isExistMentalIllness: false,
    mentalIllnessDetail: "N/A",
    isExistPhysicalIllness: false,
    physicalIllnessDetail: "",
    isAddictedDrugsAlcohol: false,
    addictedDrugsAlcoholDetail: "",
    autoBiography: "",
    digitalSignature: "",
  };
  const OldStudentLongCourseAddData: any = {
    gender: "Female",
    isForeigner: false,

    fullName: "",
    fatherName: "",
    dateOfBirth: "",
    age: "",
    nationality: "",
    idType: "",
    idCardNumber: "",
    phoneNumber: "",
    education: "",
    occupation: "",
    email: "",
    country: "",
    state: "",
    city: "",
    yearOfAppointment: " ",
    fullyCommited: false,
    withoutOtherPractice: false,
    yearOfDailyPractice: " ",
    practiceDetail: " ",
    maintainedFivePrecepts: false,
    abstainedSexualMisconduct: false,
    abstainedAlcoholDrugs: false,
    meetAllRequirements: false,
    requirementsMismatchReason: " ",
    first10DCourseDate: " ",
    first10DCourseLocation: " ",
    first10DCourseTeacher: " ",
    recent10DCourseDate: " ",
    recent10DCourseLocation: " ",
    recent10DCourseTeacher: " ",
    recentLongCourseDate: " ",
    recentLongCourseLocation: " ",
    recentLongCourseTeacher: " ",
    numOf10DServed: "",
    servedOther: " ",
    relationshipInPastYear: false,
    isLifeLongRelationship: false,
    spouseName: " ",
    harmoniousRelation: false,
    spouseFavorTakingCourse: false,
    isSpouseMeditator: false,
    isSpousePracticeOther: false,
    isExistMentalIllness: false,
    mentalIllnessDetail: " ",
    isExistPhysicalIllness: false,
    physicalIllnessDetail: " ",
    presentlyTakingMedication: false,
    dosagesDetail: " ",
    beenRefusedAdmission: false,
    refusedReasonDetail: " ",
    beenAskedToStopMeditation: false,
    askedStopReasonDetail: " ",
    pastYearPersonalTragedy: false,
    personalTragedyDetail: " ",
    particularDifficulties: " ",
    specialRequirements: " ",
    languagesSkill: " ",
    additionalInformation: " ",
    intendArrivalToCenter: " ",
    digitalSignature: " ",
    admissionStatus: " ",
    appliedFrom: " ",
  };

  useEffect(
    () => {
      // setAvailableGender(tempGender)
      let temp: any = [];
      tempData?.scheduleInfo?.availability?.forEach(
        (item: any, index: number) => {
          if (
            item.applicantType === "Student" &&
            item.attendeeType === "New" &&
            item.formStatus === "Open"
          ) {
            let _temp: any = [];
            temp.forEach((item: any) => _temp.push(item));
            let isExist = false;
            _temp.forEach((item: any, index: number) => {
              if (item === "New Student") {
                isExist = true;
              }
            });
            if (!isExist) {
              temp.push("New Student");
            }
            setSelectedOption("New Student");
          } else if (
            item.applicantType === "Server" &&
            item.attendeeType === null &&
            item.formStatus === "Open"
          ) {
            let _temp: any = [];
            temp.forEach((item: any) => _temp.push(item));
            let isExist = false;
            _temp.forEach((item: any, index: number) => {
              if (item === "Server") {
                isExist = true;
              }
            });
            if (!isExist) {
              temp.push("Server");
            }
            setSelectedOption("Server");
          } else if (
            item.applicantType === "Student" &&
            item.attendeeType === "Old" &&
            item.formStatus === "Open"
          ) {
            let _temp: any = [];
            temp.forEach((item: any) => _temp.push(item));
            let isExist = false;
            _temp.forEach((item: any, index: number) => {
              if (item === "Old Student") {
                isExist = true;
              }
            });
            if (!isExist) {
              temp.push("Old Student");
            }
            setSelectedOption("Old Student");
          }
        }
      );
      setSelectData(temp);
    },
    // eslint-disable-next-line
    []
  );

  const getLabel = (label: string) => {
    let returnLabel = "";
    if (language === "EN") {
      returnLabel = label;
    } else {
      if (label === "New Student") {
        returnLabel = "ယောဂီအသစ်";
      } else if (label === "Old Student") {
        returnLabel = "ယောဂီဟောင်း";
      } else {
        returnLabel = "ဓမ္မလုပ်သား";
      }
    }
    return returnLabel;
  };
  const goNext = () => {
    if (checked) {
      if (
        selectedOption === "Old Student" &&
        tempData.scheduleInfo.courses.coursesTitleId === 4
      ) {
        history("/long-course-registration-form", {
          state: { item: tempData },
        });
        tempData.answeredData = OldStudentLongCourseAddData;
        localStorage.setItem("RegisterData", JSON.stringify(tempData));
      } else if (selectedOption === "Old Student") {
        history("/old-student-form", { state: { item: tempData } });
        tempData.answeredData = OldStudentAddData;
        localStorage.setItem("RegisterData", JSON.stringify(tempData));
      } else if (selectedOption === "New Student") {
        history("/new-student-form", { state: { item: tempData } });
        tempData.answeredData = addData;
        localStorage.setItem("RegisterData", JSON.stringify(tempData));
      } else if (selectedOption === "Server") {
        tempData.answeredData = ServerAddData;
        localStorage.setItem("RegisterData", JSON.stringify(tempData));
        history("/server-form", {
          state: { item: tempData },
        });
      }
    } else {
      toast.error("Please, agree to term and condition ");
    }
  };
  const goCenters = () => {
    history("/centers");
  };
  return (
    <>
      <Layout>
        <PageBanner title={<Lang label="cod" />} label="" />
        <Container className="my-5">
          <span className="lh-2">
            <Lang label="introVipasana" />
          </span>
          <div className="f-col my-3">
            <h5 className="text-blue">
              <Lang label="whatVipasanaIsNot" />
            </h5>
            <div className="lh-2">
              <Lang label="aimLists" />{" "}
            </div>
          </div>
          <div className="f-col my-3">
            <h5 className="text-blue">
              <Lang label="whatVipasanaIs" />
            </h5>
            <div className="lh-2">
              <Lang label="aimLists_2" />{" "}
            </div>
          </div>
          <span className="lh-2 mb-2">
            <Lang label="rule_1" />
            <br />
            <Lang label="rule_2" />
          </span>
          <div className="f-col my-2">
            <h5 className="text-blue">
              <Lang label="content_2" />
            </h5>
            <span className="lh-2">
              <Lang label="content_2do" />{" "}
            </span>
          </div>
          <div className="f-col my-2">
            <h5 className="text-blue">
              <Lang label="content_3" />
            </h5>
            <span className="lh-2">
              <Lang label="content_3do" />{" "}
            </span>
          </div>
          <div className="f-col my-3">
            <h5 className="text-blue">
              <Lang label="content_1" />
            </h5>
            <span className="lh-2">
              <Lang label="content_1do" />
            </span>
          </div>
          <div className="f-col my-3">
            <div className="lh-2">
              <li>
                <Lang label="rule1" />
              </li>
              <li>
                <Lang label="rule2" />
              </li>
              <li>
                <Lang label="rule3" />
              </li>
              <li>
                <Lang label="rule4" />
              </li>
              <span className="my-1">
                <li>
                  <Lang label="rule5" />
                </li>
              </span>
              <li>
                <Lang label="rule6" />
              </li>
              <li>
                <Lang label="rule7" />
              </li>
              <span className="my-1">
                <Lang label="rule8" />
              </span>
            </div>
          </div>
          {Cod.map((item: any, index: number) => (
            <div className="f-col my-3" key={index}>
              <h5 className="text-blue">{item.title}</h5>
              <span className="lh-2">{item.label}</span>
            </div>
          ))}
          <Table className="table table-borderless mb-5">
            <tbody>
              {timetable.map((item: any, index: number) => (
                <tr key={index}>
                  <td className="ps-4 text-blue">{item.time}</td>
                  <td className="ps-4 text-blue">{item.activity}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {privacy.map((item: any, index: number) => (
            <div className="f-col my-3" key={index}>
              <h5 className="text-blue">{item.title}</h5>
              <span className="lh-2 mb-3">{item.description}</span>
            </div>
          ))}
          <div className="d-flex flex-center my-4">
            <Form.Check
              type="checkbox"
              label={
                <>
                  <Lang label="acceptRules" />
                </>
              }
              id="termsAndConditin"
              checked={checked}
              onChange={(e: any) => setChecked(e.target.checked)}
            />
          </div>
          <div className="d-flex p-2">
            {selectData.length === 1 ? (
              <div className="bold">
                <Lang label="available" /> - {getLabel(selectedOption)}
              </div>
            ) : (
              <>
                {selectData.map((temp: any, index: any) => (
                  <div key={index}>
                    <Form.Check
                      inline
                      label={getLabel(temp)}
                      type={"radio"}
                      id={temp}
                      name="active"
                      checked={temp === selectedOption}
                      onChange={() => setSelectedOption(temp)}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="d-flex flex-center mb-3">
            <Button variant="danger" className="mx-3" onClick={goCenters}>
              <Lang label="cancle" />
            </Button>
            <Button className="" onClick={goNext}>
              <Lang label="next" />
            </Button>
          </div>
        </Container>
      </Layout>
    </>
  );
};
export default IntroTechnique;
