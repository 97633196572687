import { useEffect, useState } from "react";
import { Container, Col } from "react-bootstrap";
import { Lang, PageBanner } from "../../components";
import OLMap from "../../components/OLMap";
import { getRequest } from "../../services/fetch";
import CenterHeader from "../centers/centerview/Header";

function OfficeMap() {
  // const [scriptLoaded, setScriptLoaded] = useState(false);
  // const [distanceInKm] = useState<number>(-1);
  const [, setData] = useState([]);

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = async () => {
    await getRequest({
      route: `centers/0/30`,
      isPrivate: false,
    }).then((result) => {
      setData(result.data);
    });
  };

  // const renderDistanceSentence = () => {
  //   return (
  //     <section className="distance-info">
  //       {`Distance between selected marker and home address is ${distanceInKm}km.`}
  //     </section>
  //   );
  // };

  return (
    <section>
      <PageBanner
        title={<Lang label="centers" />}
        label={<Lang label="headerDescription" />}
      />
      <Container className="my-5">
        <div className="d-flex mb-4">
          <CenterHeader />
          <Col className="d-flex flex-end flex-center">
            <span className="text-blue me-2  d-none d-md-inline-block">
              <Lang label="centerTotal" />
            </span>
          </Col>
        </div>
        <div className="mb-3" />
        <OLMap />
      </Container>
    </section>
  );
}

export default OfficeMap;
