import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Lang, PageBanner } from "../../../components";
import Layout from "../../../components/layout/Layout";

const FormComplete = () => {
  const navigate = useNavigate();
  const handleExit = () => {
    navigate("/centers");
  };

  return (
    <>
      <Layout>
        <PageBanner title={<Lang label="thankLetter" />} label=" " />
        <Container className="my-3 text-blue fw-bold">
          <h6 className="mb-4">
            <Lang label="confirm_1" />
          </h6>
          <h6 className="mb-4">
            <Lang label="confirm_2" />
          </h6>
          <h6 className="mb-4">
            <Lang label="confirm_3" />
          </h6>
          <h6 className="mb-4">
            <Lang label="confirm_4" />
          </h6>
          <div className="my-5 flex-center">
            <Button onClick={handleExit}>
              <Lang label="exit" />
            </Button>
          </div>
          {/* <Button className="ms-3" onClick={PrintAction}>
              DownLoad as pdf
            </Button> */}
          {/* <Col md={8}>
                        <ComponentToPrint ref={exportComponentRef} />
                    </Col> */}
          {/* <section className="d-none">
            <PDF ref={componentRef} />
          </section> */}
        </Container>
      </Layout>
    </>
  );
};
export default FormComplete;
