import { createContext, useState } from "react";

const contextDefaultValues: any = {
  center: {},
  //personal info
  appliedFrom: "Online",
  fullName: "",
  fatherName: "",
  dateOfBirth: "",
  age: "",
  gender: "Female",
  nationality: "Afghanistan",
  idType: "NRC",
  idCardNumber: "",
  phoneNumber: "",
  education: "",
  occupation: "",
  email: "",
  country: "",
  state: "",
  city: "",
  completeAddress: "",
  isNewStudent: false,
  teacherNameOne: "",
  teacherNameTwo: "",
  courseStartDate: "",
  student: "",
  attendeeType: "",
  applicantType: "Student",
  courseEndDate: "",
  //new student
  termsReadDiscipline: false,
  termsAcceptDiscipline: false,
  termsDenyToLeave: false,
  termsSilent: false,
  termsNotReligious: false,
  termsRefrainAlcoholDrugs: false,
  emergencyContact: "",
  relativeAttendeeInfo: "",
  termsAgree: false,
  isYourSpouseAttendee: false,
  //server
  isReadServerDisciplines: false,
  autoBiography: "",
  lastCourseServed: "",
  satCoursesTimes: "",
  satLastCourses: "",
  servedCourse: "",
  lastServedCourseDate: "",
  lastServedDhammaCenter: "",
  lastServedDhammaTeacher: "",
  lastAttendedCourseDate: "",
  lastAttendedDhammaCenter: "",
  lastAttendedDhammaTeacher: "",
  servedCourseTimes: "",
  willingToVolunteer: false,
  nrc: "",
  nrcDigit: "",
  nrcArea: "",
  nrcType: "",
  nrcNumber: "",
  studentAddress: "",
  firstCourseDate: "",
  firstCourseTeacher: "",
  firstCourseCenterName: "",
  firstCourseCenterTownship: "",
  recentCourseDate: "",
  recentCourseTeacher: "",
  recentCourseCenterName: "",
  recentCourseCenterTownship: "",
  tenDayCourse: "0",
  tenDayServices: "0",
  stpCourse: "0",
  stpServices: "0",
  twentyDayCourse: "0",
  twentyDayServices: "0",
  thirtyDayCourse: "0",
  thirtyDayServices: "0",
  fortyFiveDayCourse: "0",
  fortyFiveDayServices: "0",
  sixtyDayCourse: "0",
  sixtyDayServices: "0",
  gcDayCourse: "0",
  gcDayServices: "0",
  selfCourse: "0",
  selfServices: "0",
  tenDaySpecialCourse: "0",
  tenDaySpecialServices: "0",
  totalCourses: "0",
  totalServices: "0",
  languagesSkill: "",
  practiceKnownFrom: "",
  isPracticeOtherTeqInPast: false,
  otherTeqInPastDetail: "",
  isMaintainPractice: false,
  maintainPracticeDetail: "",
  isExistPhysicalIllness: false,
  physicalIllnessDetail: "",
  isExistMentalIllness: false,
  mentalIllnessDetail: "",
  practicedMeditationTechniques: false,
  practicedMeditationTechniquesDetail: "",
  isPracticeOtherMeditaitonTeq: false,
  otherMeditaitonTeqDetail: "",
  isaddictedDrugsAlcohol: false,
  addictedDrugsAlcoholDetail: "",
  noticeChangesInHealth: "",
  noticechangesInCommunication: "",
  noticeChangesInAddiction: "",
  aboutBiography: "",
  strictlyFollowTheRules: false,
  familyRelationship: "",
  aboutMindState: "",
  severeDepression: "",
  purposeOfEntrance: "",
  submissionDate: new Date(),
  digitalSignature: "",
  //old student history
  student10Days: "",
  studentSathi: "",
  student20Days: "",
  student30Days: "",
  student45Days: "",
  student60Days: "",
  studentTSC: "",
  studentSelf: "",
  studentSpecial10Days: "",
  server10Days: "",
  serverSathi: "",
  server20Days: "",
  server30Days: "",
  server45Days: "",
  server60Days: "",
  serverTSC: "",
  serverSelf: "",
  serverSpecial10Days: "",
  //long course form
  yearOfAppointment: "",
  fullyCommited: "",
  withoutOtherPractice: false,
  yearOfDailyPractice: "",
  practiceDetail: "",
  maintainedFivePrecepts: false,
  abstainedSexualMisconduct: false,
  abstainedAlcoholDrugs: false,
  meetAllRequirements: false,
  requirementsMismatchReason: "",
  first10DCourseDate: "",
  first10DCourseLocation: "",
  first10DCourseTeacher: "",
  recent10DCourseDate: "",
  recent10DCourseLocation: "",
  recent10DCourseTeacher: "",
  recentLongCourseDate: "",
  recentLongCourseLocation: "",
  recentLongCourseTeacher: "",
  numOf10DServed: "",
  servedOther: "",
  relationshipInPastYear: false,
  isLifeLongRelationship: false,
  spouseName: "",
  harmoniousRelation: false,
  spouseFavorTakingCourse: false,
  isSpouseMeditator: false,
  isSpousePracticeOther: false,
  presentlyTakingMedication: false,
  dosagesDetail: "",
  beenRefusedAdmission: false,
  refusedReasonDetail: "",
  beenAskedToStopMeditation: false,
  askedStopReasonDetail: "",
  pastYearPersonalTragedy: false,
  personalTragedyDetail: "",
  particularDifficulties: "",
  specialRequirements: "",
  additionalInformation: "",
  intendArrivalToCenter: "",
  isForeigner: false,
};

const CourseAppFormContext = createContext<any>(contextDefaultValues);

function CourseAppFormProvider(props: any) {
  const [courseAppForm, setCourseAppForm] = useState([]);

  return (
    <CourseAppFormContext.Provider value={[courseAppForm, setCourseAppForm]}>
      {props.children}
    </CourseAppFormContext.Provider>
  );
}

export { CourseAppFormContext, CourseAppFormProvider };
