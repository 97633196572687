import { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Lang, PageBanner } from "../../../components";
import { SearchInput } from "../../../components/common/CustomInput";
import { IconCalender, IconEye } from "../../../components/common/Icons";
import { TableLoading } from "../../../components/common/Loading";
import { PgComponent } from "../../../components/common/Pagination";
import { CourseAppFormContext } from "../../../context/Form";
import { LangContext } from "../../../context/Lang";
import { PageContext } from "../../../context/Page";
import { getNumber } from "../../../services";
import { getRequest } from "../../../services/fetch";
import CenterHeader from "./Header";
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT_LIST;

const ListLayout = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useContext(PageContext);
  const [courseAppForm, setCourseAppForm] = useContext(CourseAppFormContext);
  const {
    state: { language },
  } = useContext(LangContext);

  useEffect(() => {
    handleGetData(page.current);
    // eslint-disable-next-line
  }, []);

  const handleGetData = async (skip: number) => {
    setLoading(true);
    await getRequest({
      route: `centers/${skip * limit}/${limit}`,
      isPrivate: false,
    }).then((result) => {
      setData(result.data);
      setPage({
        current: skip,
        total: result.count,
      });
      setInterval(() => {
        setLoading(false);
      }, 1000);
    });
  };
  const goDetail = (item: any) => {
    localStorage.setItem(
      "RegisterData",
      JSON.stringify({
        centerinfo: item,
        scheduleInfo: {},
        answeredData: {},
      })
    );
    setCourseAppForm({ ...courseAppForm, center: item });
    navigate("/center-detail", { state: { id: item.id } });
  };
  const handleGetSearchData = (skip: number, searchString: string) => {
    setLoading(true);
    getRequest({
      route: `centers/${skip * limit}/${limit}?keyword=${searchString}`,
      isPrivate: false,
    }).then((result) => {
      setData(result.data);
      setPage &&
        setPage({
          current: skip,
          total: result.count,
        });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };

  const handleSerachEnterAction = (e: any) => {
    if (e.keyCode === 13) {
      if (e.target.value) {
        handleGetSearchData(0, e.target.value);
      } else {
        handleGetData(0);
        setSearchString("");
      }
    }
  };
  const handleSerachAction = () => {
    handleGetSearchData(0, searchString);
  };
  return (
    <>
      <PageBanner
        title={<Lang label="centers" />}
        label={<Lang label="headerDescription" />}
      />
      <Container className="mt-5">
        <div className="d-flex mb-4">
          <CenterHeader />
          <Col className="d-flex flex-end flex-center">
            <span className="text-blue me-2  d-none d-md-inline-block">
              <Lang label="centerTotal" />: {page.total}
            </span>
            <SearchInput
              handleSerachEnterAction={handleSerachEnterAction}
              handleSerachAction={handleSerachAction}
              searchString={searchString}
              setSearchString={setSearchString}
              handleClearSearch={() => {
                handleGetData(0);
                setSearchString("");
              }}
            />
          </Col>
        </div>
        <section className="d-none d-md-block">
          <section>
            {loading ? (
              <TableLoading />
            ) : (
              <>
                {data.length > 0 && (
                  <Table responsive className="mb-4" striped>
                    <thead>
                      <tr>
                        <th className="ps-4">#</th>
                        <th>
                          <Lang label="centerName" />
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item: any, index: number) => (
                        <tr key={index} className="cursor">
                          <td className="ps-4">
                            {getNumber(index, page.current)}
                          </td>
                          <td onClick={() => goDetail(item)}>
                            {language === "EN"
                              ? item.centerNameEN
                              : item.centerNameMM}
                          </td>
                          <td className="">
                            <span className="flex-end me-3">
                              <span>
                                <IconEye size={16} color="#002299" />
                                <small
                                  className=" text-blue hover"
                                  onClick={() => goDetail(item)}
                                >
                                  <Lang label="detail" />
                                </small>
                              </span>
                              <span
                                className="mx-5"
                                onClick={() => goDetail(item)}
                              >
                                <IconCalender size={16} color="#002299" />
                                <small className=" text-blue hover">
                                  <Lang label="courses" />
                                </small>
                              </span>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </>
            )}
          </section>
        </section>
        <div className="d-md-none d-sm-block">
          <Row className="d-flex flex-wrap p-2 text-muted border-bottom border-top  fw-bold">
            {loading ? (
              <TableLoading />
            ) : (
              <>
                {data.map((item: any, index: number) => (
                  <Col className="mb-3 bg-card m-2 p-2" sm={12} key={index}>
                    <div className="p-1 col-12">
                      <span>{getNumber(index, page.current)}</span> .
                      {item.centerNameEN}
                    </div>
                    <div className="p-1 col-12 d-flex">
                      <span>
                        <IconEye size={16} color="#002299" />
                        <small
                          className=" text-blue cursor"
                          onClick={() => goDetail(item)}
                        >
                          <Lang label="detail" />
                        </small>
                      </span>
                      <span className="mx-3" onClick={() => goDetail(item)}>
                        <IconCalender size={16} color="#002299" />
                        <small className=" text-blue cursor">
                          <Lang label="courses" />
                        </small>
                      </span>
                    </div>
                  </Col>
                ))}
              </>
            )}
          </Row>
        </div>
      </Container>
      {page && page.total > limit && (
        <PgComponent
          currentPage={page.current + 1 || 0}
          fromCount={page.current || 0}
          total={page.total || 0}
          paginationLimit={limit}
          pageInfo={page}
          setPage={setPage}
          handleGetData={handleGetData}
        />
      )}
    </>
  );
};

export default ListLayout;
