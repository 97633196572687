import React, { useState, createContext } from 'react'

interface Variable {
  //   isSidebarActive: boolean
  //   isList: boolean
  activeType: string
}

const contextDefaultValues: Variable = {
  //   isSidebarActive: true,
  //   isList: false,
  activeType: 'list',
}

const VariableContext = createContext<any>(contextDefaultValues)

function VariableProvider(props: any) {
  const [variable, setVariable] = useState<Variable>()

  return (
    <VariableContext.Provider value={[variable, setVariable]}>
      {props.children}
    </VariableContext.Provider>
  )
}

export { VariableContext, VariableProvider }
