import { toast } from 'react-toastify'

//Env Variable
const API_URL = process.env.REACT_APP_API_URL
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT

//Method
const getMethod = 'GET'
const postMethod = 'POST'
const putMethod = 'PUT'
const deleteMethod = 'DELETE'

export const getPersons = (skip: number, keyword?: string) => {
  return `persons/${skip * limit}/${limit}?keyword=${keyword || ''}`
}

const personsRoute = 'persons'

export { getMethod, postMethod, putMethod, deleteMethod, personsRoute }

export const actionData = async (method: string, route: string, data?: any) => {
  try {
    const response = await fetch(`${API_URL}/${route}`, {
      method: method,
      headers: { 'Content-type': 'application/json' },
      mode: 'cors',
      cache: 'default',
      body: data && JSON.stringify(data),
    })
    const json = await response.json()
    return json
  } catch (error) {
    return toast.error('Something Wrong!')
  }
}
