import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Lang, PageBanner } from "../../../../components";
import Layout from "../../../../components/layout/Layout";
import { CourseAppFormContext } from "../../../../context/Form";
import { LangContext } from "../../../../context/Lang";
import { dateFormat2, handleDateFormat } from "../../../../services";

const NewStudentForm = () => {
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const [availableGender, setAvailableGender] = useState([]);
  const [courseAppForm, setCourseAppForm] = useContext(CourseAppFormContext);
  const {
    state: { language },
  } = useContext(LangContext);

  const genders = [
    { key: "Female", label: <Lang label="female" />, customKey: true },
    { key: "Male", label: <Lang label="male" />, customKey: true },
  ];
  const addData: any = {
    gender: courseAppForm?.gender,
    termsReadDiscipline: courseAppForm.termsReadDiscipline,
    termsAcceptDiscipline: courseAppForm.termsAcceptDiscipline,
    termsDenyToLeave: courseAppForm.termsDenyToLeave,
    termsSilent: courseAppForm.termsSilent,
    termsNotReligious: courseAppForm.termsNotReligious,
    termsRefrainAlcoholDrugs: courseAppForm.termsRefrainAlcoholDrugs,
    emergencyContact: courseAppForm.emergencyContact,
    relativeAttendeeInfo: courseAppForm.relativeAttendeeInfo,
    termsAgree: courseAppForm.termsAgree,
  };
  const temp: any = localStorage.getItem("RegisterData");
  const tempData = temp && JSON.parse(temp);
  const centerInfo = tempData.centerinfo;

  localStorage.setItem("RegisterData", JSON.stringify(tempData));

  useEffect(() => {
    const data = tempData.answeredData;
    setCourseAppForm(data);
    let tempGender: any = [];
    tempData?.scheduleInfo?.availability?.forEach(
      (item: any, index: number) => {
        if (
          item.courseFor === "Male" &&
          item.applicantType === "Student" &&
          item.attendeeType === "New"
        ) {
          let _temp: any = [];
          tempGender.forEach((item: any) => _temp.push(item));
          let isExist = false;
          _temp.forEach((item: any, index: number) => {
            if (item === "Male") {
              isExist = true;
            }
          });
          if (!isExist) {
            tempGender.push(<Lang label="male" />);
          }
        } else if (
          item.courseFor === "Female" &&
          item.applicantType === "Student" &&
          item.attendeeType === "New"
        ) {
          let _temp: any = [];
          tempGender.forEach((item: any) => _temp.push(item));
          let isExist = false;
          _temp.forEach((item: any, index: number) => {
            if (item === "Female") {
              isExist = true;
            }
          });
          if (!isExist) {
            tempGender.push(<Lang label="female" />);
          }
        }
      }
    );
    setAvailableGender(tempGender);
    // eslint-disable-next-line
  }, []);
  const question = [
    { id: "termsReadDiscipline", title: <Lang label="new_qus_1" /> },
    { id: "termsAcceptDiscipline", title: <Lang label="new_qus_2" /> },
    { id: "termsDenyToLeave", title: <Lang label="new_qus_3" /> },
    { id: "termsSilent", title: <Lang label="new_qus_4" /> },
    { id: "termsNotReligious", title: <Lang label="new_qus_5" /> },
    { id: "termsRefrainAlcoholDrugs", title: <Lang label="new_qus_6" /> },
  ];

  const goNext = () => {
    // let gender = courseAppForm.gender
    let gender = courseAppForm.gender;
    let isExist = false;
    tempData?.scheduleInfo?.availability?.forEach(
      (item: any, index: number) => {
        if (
          item.attendeeType === "New" &&
          item.applicantType === "Student" &&
          item.courseFor === gender
        ) {
          isExist = true;
        }
      }
    );
    if (isExist) {
      navigate("/registration-form-new");
      const temp: any = localStorage.getItem("RegisterData");
      const tempData = temp && JSON.parse(temp);
      tempData.answeredData = addData;
      localStorage.setItem("RegisterData", JSON.stringify(tempData));
    } else {
      toast.error(`${gender} student is not available for this course`);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      toast.error("Please fill data!");
    } else {
      goNext();
    }
    setValidated(true);
  };

  return (
    <>
      <Layout>
        <PageBanner title={<Lang label="courseAppForm" />} label="" />
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Container className="my-3">
            <div className="alert alert-info mt-4" role="alert">
              <label className="text-muted mb-2">
                <Lang label="available" />
              </label>
              {availableGender.length > 0 &&
                availableGender?.map((item: any, index: number) => (
                  <h6 key={index}>- {item}</h6>
                ))}
            </div>
            <Row className="mb-3">
              <Col md={4}>
                <h6 className=" my-3">
                  <Lang label="gender" />
                </h6>
                <Form.Select
                  required
                  value={courseAppForm.gender}
                  onChange={(e) =>
                    setCourseAppForm({
                      ...courseAppForm,
                      gender: e.target.value,
                    })
                  }
                >
                  {genders.map((item: any, index: number) => (
                    <option
                      value={
                        item.customKey
                          ? item.key
                          : item.key === "value"
                          ? item.value
                          : item.label
                      }
                      key={index}
                    >
                      {item.label}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col md={4}>
                <h6 className=" my-3">{<Lang label="centerName" />}</h6>
                <Form.Control
                  type="text"
                  className="mb-3"
                  placeholder=""
                  value={
                    language === "EN"
                      ? centerInfo?.centerNameEN
                      : centerInfo?.centerNameMM
                  }
                  disabled
                />
                <Form.Control
                  as="textarea"
                  className="mb-3"
                  rows={3}
                  placeholder=" "
                  value={
                    language === "EN"
                      ? centerInfo?.addressEN
                      : centerInfo?.addressMM
                  }
                  disabled
                />
              </Col>
              <Col md={4}>
                <h6 className=" my-3">
                  <Lang label="courseDate" />
                </h6>
                <Form.Control
                  type="date"
                  className="mb-3"
                  placeholder=""
                  value={handleDateFormat(
                    tempData.scheduleInfo?.startDate,
                    dateFormat2
                  )}
                  onChange={(e) =>
                    setCourseAppForm({
                      ...courseAppForm,
                      courseStartDate: e.target.value,
                    })
                  }
                />
                <Form.Control
                  type="date"
                  className="mb-3"
                  placeholder=""
                  value={handleDateFormat(
                    tempData.scheduleInfo?.endDate,
                    dateFormat2
                  )}
                  onChange={(e) =>
                    setCourseAppForm({
                      ...courseAppForm,
                      courseEndDate: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <div className=" mb-4 fw-bold text-blue">
              <Lang label="agree" />
            </div>
            {question.map((item: any, index: number) => (
              <section key={index}>
                <Form.Check
                  type="checkbox"
                  required
                  className="mb-3"
                  label={item.title}
                  id={item.id}
                  checked={courseAppForm[item.id]}
                  onChange={() =>
                    setCourseAppForm({
                      ...courseAppForm,
                      [item.id]: !courseAppForm[item.id],
                    })
                  }
                />
              </section>
            ))}
            <section className="mb-3">
              <Lang label="new_qus_8" />
            </section>
            <section className="mb-3">
              <Lang label="new_qus_9" />
            </section>
            <section className="mb-3">
              <Lang label="new_qus_10" />
              <Row className="my-3">
                <Form.Control
                  required
                  as="textarea"
                  className="mb-3"
                  rows={3}
                  placeholder=""
                  value={courseAppForm.emergencyContact}
                  onChange={(e) =>
                    setCourseAppForm({
                      ...courseAppForm,
                      emergencyContact: e.target.value,
                    })
                  }
                />
              </Row>
            </section>
            <section className="">
              <Lang label="new_qus_11" />
              <Form.Control
                required
                as="textarea"
                className="my-3 "
                rows={3}
                placeholder=""
                value={courseAppForm.relativeAttendeeInfo}
                onChange={(e) =>
                  setCourseAppForm({
                    ...courseAppForm,
                    relativeAttendeeInfo: e.target.value,
                  })
                }
              />
            </section>
            <section>
              <Form.Check
                required
                type="checkbox"
                className="mb-3"
                label={<Lang label="new_qus_12" />}
                id="termsAgree"
                checked={courseAppForm.termsAgree}
                onChange={() =>
                  setCourseAppForm({
                    ...courseAppForm,
                    termsAgree: !courseAppForm.termsAgree,
                  })
                }
              />
            </section>

            <div className="d-flex flex-center my-5">
              <Button
                className="me-3 btn-blue fw-bold"
                onClick={() => navigate(-1)}
              >
                <Lang label="back" />
              </Button>
              <Button type="submit">
                <Lang label="next" />
              </Button>
            </div>
          </Container>
        </Form>
      </Layout>
    </>
  );
};
export default NewStudentForm;
