import React, { useContext } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Lang, PageBanner } from '../../../../components'
import Layout from '../../../../components/layout/Layout'
import { CourseAppFormContext } from '../../../../context/Form'

const ServerTimetable = () => {
  const navigate = useNavigate()
  const [courseAppForm] = useContext(CourseAppFormContext)
  const shedules = [
    { timeStart: '4:30', timeEnd: '6:30 am' },
    { timeStart: '6:30', timeEnd: '8:00 am' },
    { timeStart: '8:00', timeEnd: '9:00 am' },
    { timeStart: '9:00', timeEnd: '11:00 am' },
    { timeStart: '11:00', timeEnd: '1:00 am' },
    { timeStart: '1:00', timeEnd: '2:30 am' },
    { timeStart: '2:30', timeEnd: '3:30 am' },
    { timeStart: '3:30', timeEnd: '6:00 pm' },
    { timeStart: '6:00', timeEnd: '7:00 pm' },
    { timeStart: '7:00', timeEnd: '9:00 pm' },
    { timeStart: '9:00', timeEnd: '10:30 pm  Metta Period' },
  ]
  const handleSubmit = () => {
    console.log(courseAppForm)

    navigate('/registration-complete')
  }
  return (
    <Layout>
      <PageBanner title={<>Server TimeTables</>} label="" />
      <Container className="my-5">
        <div className="my-3">
          <span className="mb-5">
            This timetable is to be filled clearly indicating the hours of
            mediataion ,service and rest .
          </span>
          {shedules.map((item: any, index: number) => (
            <span className=" ms-5 mb-2 d-block">
              {item.timeStart} to {item.timeEnd}
            </span>
          ))}
        </div>
        <span className="my-2">
          All Dhamma worker must attend the Metta Period
        </span>
        <div className="my-2">If you face any difficult ,meets the AT.</div>
        <div className="d-flex flex-center my-3">
          <Button className=" btn-blue" onClick={() => navigate(-1)}>
            Back
          </Button>
          <Button variant="danger" className="mx-3">
            <Lang label="cancle" />
          </Button>
          <Button className="" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Container>
    </Layout>
  )
}
export default ServerTimetable
