import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
//Date Variable
const defaultTimeZone = "Asia/Yangon";
//const dateTimeFormat = 'hh:mm A - DD-MM-YYYY'
const dateTimeFormat = "YYYY-MM-DD hh:mm A";
const dateFormat = "DD-MM-YYYY";
const dateFormat2 = "YYYY-MM-DD";
const dateFormat3 = "D MMM YYYY";

const limit: any = process.env.REACT_APP_PAGINATION_LIMIT_LIST;
const USER = "user";

export { dateFormat2 };
export { dateFormat3 };

export const handleClose: any = (setShow: any) => setShow(false);
export const handleShow = (setShow: any) => setShow(true);

export const getNumber = (index: number, skip: number) => {
  return index + 1 + skip * limit;
};

export const handleDateFormat = (date: any, format?: string) => {
  return dayjs(date)
    .tz(defaultTimeZone)
    .format(format || dateFormat);
};

export const handleDateTimeFormat = (date: any) => {
  return dayjs(date).tz(defaultTimeZone).format(dateTimeFormat);
};

export function numberFormat(x: any) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
}

export function toFloat(x: any) {
  return x
    ? parseFloat(x)
        .toFixed(3)
        .replace(/\.?0+$/, "")
    : 0;
}

export const login = (user: any) => {
  localStorage.setItem(USER, user);
};

export const logout = () => {
  localStorage.removeItem(USER);
};

export const getStoreUser = () => {
  const tempUser = localStorage.getItem("user");
  const user = tempUser && JSON.parse(tempUser);
  return user;
};

export const isLogin = () => {
  if (localStorage.getItem(USER)) {
    return true;
  }
  return false;
};

//For Number Input
export const disableScroll = (e: any) => {
  e.currentTarget.blur();
};

export const handleKeyDown = (event: any) => {
  if (event.key.toLowerCase() === "enter") {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
};

export const inputChangeService = (
  evt: any,
  formData: any,
  setFormData: any,
  wantString?: boolean
) => {
  const target = evt.target;
  const name = target.id;
  let value;

  if (target.type === "checkbox") {
    value = target.checked;
  } else if (target.type === "number" || target.type === "select-one") {
    if (target.value === "") {
      value = "";
    } else {
      value = target.value;
      if (wantString) {
        value = target.value;
      } else {
        value = parseFloat(target.value);
      }
    }
  } else {
    value = target.type === "text" ? target.value : target.value;
  }

  setFormData({
    ...formData,
    [name]: value,
  });
};

export const openUrl = (link: string) => {
  // window.open('https://facebook.com/mgmg', '_blank');
  window.open(link, "_blank");
};
export const defineAccess = (array: any) => {
  return array.some((item: any) => item === userLevel());
};

export const userLevel = () => {
  let role = getStoreUser().roleName;
  let level = 6;
  if (role === "Owner" || role === "Technical") level = 1;
  if (role === "Admin") level = 2;
  if (role === "Manager") level = 3;
  if (role === "AsstTeacher") level = 4;
  if (role === "Operator") level = 5;
  return level;
};

export const formDataService = ({
  formData,
  sendNull = false,
}: {
  formData: any;
  sendNull?: boolean;
}) => {
  let data: any = {};

  for (let [key, value] of Object.entries(formData)) {
    if (sendNull) {
      if (typeof value === "string") {
        if (value === "") {
          data[key] = null;
        } else {
          data[key] = value.trim();
        }
      } else {
        if (value === "") {
          data[key] = null;
        } else {
          data[key] = value;
        }
      }
    } else {
      if (value !== null && value !== "") {
        if (typeof value === "string") {
          data[key] = value.trim();
        } else {
          data[key] = value;
        }
      }
    }
  }
  return data;
};
export const checkRequired = (data: any, required: any) => {
  let isReqiured = false;
  for (const field of required) {
    if (data.hasOwnProperty(field) === false) {
      isReqiured = true;
    }
  }
  return isReqiured;
};
