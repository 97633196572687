import ScrollToTop from '..'
import Footer from './Footer'
import { ToastContainer } from 'react-toastify'
import TopNav from './TopNav'

function Layout({ children }: { children: any }) {
  return (
    <div>
      <TopNav />
      <div className="">
        <ToastContainer autoClose={1500} />
        {children}
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  )
}
export default Layout
