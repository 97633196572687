import { Col, Form, Row } from 'react-bootstrap'
import { useState } from 'react'

import {
  CustomeSuggestionSelect,
  CustomInput,
  CustomSelectInput,
  CustomSelectInputGroup,
  DateInput,
} from './CustomInput'

const FormFieldService = ({
  fields,
  required,
  handleChange,
  handleInputChange,
  formData,
  setFormData,
  colCount = 12,
  bg = false,
  value,
}: {
  fields: any
  handleChange: any
  handleInputChange?: any
  formData?: any
  setFormData?: any
  colCount?: number
  required?: any
  bg?: boolean
  value?: any
}) => {
  const [validated] = useState(false)
  return (
    <div className={bg ? '' : ''}>
      <Form validated={validated}>
        <Row>
          {fields.map(
            (item: any, index: number) =>
              !item.hide && (
                <Col md={item.colCount ? item.colCount : colCount} key={index}>
                  {item.type === 'select' ? (
                    <CustomSelectInput
                      id={item.id}
                      label={item.label}
                      value={item.value}
                      data={item.data}
                      required={required}
                      wantString={item.wantString}
                      handleChange={handleChange}
                      disableMargin={item.disableMargin}
                      formData={formData}
                      setFormData={setFormData}
                      checkId={item.checkId}
                      checkLabel={item.checkLabel}
                      checkLabelValue={item.checkLabelValue}
                      checkFirstValue={item.checkFirstValue}
                      checkSecondValue={item.checkSecondValue}
                      disableInititalSelect={item.disableInititalSelect}
                      keyType={item.keyType}
                    />
                  ) : item.type === 'select-group' ? (
                    <CustomSelectInputGroup
                      label={item.label}
                      id={item.id}
                      secondId={item.secondId}
                      value={item.value}
                      secondValue={item.secondValue}
                      // disableMargin={item.disableMargin}
                      handleChange={handleChange}
                      inputType={item.inputType}
                      wantString={item.wantString}
                      data={item.data}
                    />
                  ) : item.type === 'date' ? (
                    <DateInput
                      required={item.required}
                      id={item.id}
                      label={item.label}
                      value={item.value}
                      handleChange={handleChange}
                    />
                  ) : item.type === 'suggestion-select' ? (
                    <CustomeSuggestionSelect
                      handleInputChange={handleInputChange}
                      value={item.value}
                      id={item.id}
                      // secondId={item.secondId}
                      label={item.label}
                      data={item.data}
                      formData={formData}
                      setFormData={setFormData}
                      defaulLabel={item.defaultLabel}
                    />
                  ) : (
                    <CustomInput
                      required={item.required}
                      id={item.id}
                      label={item.label}
                      value={item.value}
                      handleChange={handleChange}
                      inputType={item.inputType}
                      wantString={item.wantString}
                      disableMargin={item.disableMargin}
                      disable={item.disable}
                      colCount={item.colCount}
                    />
                  )}
                </Col>
              )
          )}
        </Row>
      </Form>
    </div>
  )
}

export default FormFieldService
