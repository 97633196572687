import { useContext, useState } from "react";
import { Container, Nav, Navbar, Offcanvas, Image } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { List } from "../common/Icons";
import EnglishFlag from "../../assets/images/lang/flag-eng-vn.svg";
import MyanmarFlag from "../../assets/images/lang/myanmar-circle.svg";
import { LangContext } from "../../context/Lang";
import { Lang } from "..";

function TopNav() {
  const {
    state: { language },
    dispatch: { setLanguage },
  } = useContext(LangContext);
  const [show, setShow] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/")[1];
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const chooseLanguageHandler = (value: string) => {
    setLanguage(value);
  };
  const goCenters = () => {
    history("/centers");
  };

  return (
    <>
      <div className="header ">
        <Container className="">
          <div className="">
            <div className=" d-flex align-items-center justify-content-between">
              <section className="d-flex align-items-center ">
                <Navbar.Brand href="/">
                  <Image src="images/logo.gif" width="40" className="m-2" />
                </Navbar.Brand>
                <span className="fw-bold fs-4">
                  <Lang label="title" />
                </span>
              </section>
              <div className="flex-center">
                <Nav activeKey="/">
                  <>
                    <Nav.Link
                      className={`header-nav-link ${
                        splitLocation === "" ? "active" : "inactive"
                      }`}
                      onClick={() => history("/")}
                    >
                      <Lang label="home" />
                    </Nav.Link>
                    <Nav.Link
                      className={`header-nav-link ${
                        splitLocation === "centers" ? "active" : "inactive"
                      }`}
                      onClick={goCenters}
                    >
                      <Lang label="centers" />
                    </Nav.Link>
                  </>
                </Nav>
                {language === "EN" ? (
                  <label
                    className="cursor"
                    onClick={() => chooseLanguageHandler("MM")}
                  >
                    <Image src={MyanmarFlag} width="25" className="me-2" />
                  </label>
                ) : (
                  <label
                    className="cursor"
                    onClick={() => chooseLanguageHandler("EN")}
                  >
                    <Image src={EnglishFlag} width="25" className="me-2" />
                  </label>
                )}
              </div>
            </div>
          </div>
        </Container>

        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          id="search-offcanvas"
        >
          <Offcanvas.Header closeButton className="border-bottom bg-light">
            <Offcanvas.Title>
              <div className="d-flex">
                <span className="fw-bold sm-text">
                  <Lang label="title" />
                </span>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav.Link className="mb-2 text-muted" onClick={() => history("/")}>
              <Lang label="home" />
            </Nav.Link>
            <Nav.Link className="mb-2 text-muted" onClick={goCenters}>
              <Lang label="centers" />
            </Nav.Link>
            <Nav.Link className="mb-2 text-muted">
              <span className="">
                {language === "EN" ? (
                  <label
                    className="cursor"
                    onClick={() => chooseLanguageHandler("MM")}
                  >
                    <Image src={MyanmarFlag} width="25" className="me-2" />
                  </label>
                ) : (
                  <label
                    className="cursor"
                    onClick={() => chooseLanguageHandler("EN")}
                  >
                    <Image src={EnglishFlag} width="25" className="me-2" />
                  </label>
                )}
              </span>
            </Nav.Link>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <section className="sm-top-nav px-3 py-2">
        <Navbar.Brand href="/">
          <Image src="images/logo.gif" width="30" />
        </Navbar.Brand>
        <div>
          <span className=" fw-bold sm-text">
            <Lang label="title" />
          </span>
        </div>
        <span className="me-2" onClick={handleShow}>
          <List />
        </span>
      </section>
    </>
  );
}

export default TopNav;
