import React from "react";
import Map from "ol/Map";
import View from "ol/View";
import Overlay from "ol/Overlay";
import { fromLonLat } from "ol/proj";
import { Style, Icon, Stroke, Fill } from "ol/style";
import { Coordinate } from "ol/coordinate";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import GeoJSON from "ol/format/GeoJSON";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { createEmpty, extend } from "ol/extent";
import {
  defaults as defaultControls,
  Attribution,
  FullScreen,
} from "ol/control";
import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// data
import dataJson from "./data/centers.json";
import mapMarker from "./images/meditation-center-icon.png";

// style
import "./styles/ol-map.css";

let map: any;
let popupContainer: any;
let popupcloser: any;
let overlay: Overlay;

const mapZoom = 6;
const layers: never[] = [];
const bbox = createEmpty();

const OSMLayer = new TileLayer({
  source: new OSM(),
});

interface Props {}

const OLMap: React.FC<Props> = () => {
  const navigate = useNavigate();
  const [dataInfo, setDataInfo] = React.useState<any>(null);

  const initMap = () => {
    popupContainer = document.getElementById("ol-popup");
    popupcloser = document.getElementById("ol-popup-closer");

    overlay = new Overlay({
      element: popupContainer,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });

    popupcloser.onclick = () => {
      overlay.setPosition(undefined);
      popupcloser.blur();
      return false;
    };

    map = new Map({
      target: "ol-map",
      controls: defaultControls().extend([
        new Attribution({
          collapsible: false,
        }),
        new FullScreen({
          source: "ol-map",
        }),
      ]),
      overlays: [overlay],
      layers: [OSMLayer],
      view: new View({
        center: fromLonLat(dataJson.center),
        zoom: mapZoom,
      }),
    });

    map.on("singleclick", (evt: { coordinate: any; pixel: any }) => {
      let coordinate = evt.coordinate;
      let pixel = evt.pixel;
      getFeatureInfoJSON(pixel, coordinate);
    });

    map.on("pointermove", (evt: any) => {
      changeCursor(evt);
    });
  };

  const getClientsData = () => {
    if (dataJson) {
      let zIndex = 800;
      dataJson.centers.forEach((d) => {
        loadGeoJson(d, zIndex++, layers);
      });
      fitBound();
    }
  };

  React.useEffect(() => {
    initMap();
    getClientsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFeatureInfoJSON = (
    pixel: any,
    coordinate: Coordinate | undefined
  ) => {
    let feature = map.forEachFeatureAtPixel(pixel, (feature: any) => {
      return feature;
    });

    if (feature) {
      let featureInfo = feature.getProperties();

      if (featureInfo) {
        setDataInfo(featureInfo);
        overlay.setPosition(coordinate);
      }
    } else {
      popupcloser.onclick();
    }
  };

  const MakeHtml = () => {
    return (
      <>
        {dataInfo ? (
          <div>
            <h4 className="text-center mb-3">{dataInfo.centerNameMM}</h4>
            <Table borderless>
              <tbody>
                <tr>
                  <td>Email</td>
                  <td>{dataInfo.email}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>
                    {dataInfo.Phone}, {dataInfo.phoneOther}
                  </td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>{dataInfo.addressMM}</td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td>
                    {dataInfo.longitude}, {dataInfo.latitude}
                  </td>
                </tr>
              </tbody>
            </Table>

            <div className="text-center">
              <Button
                variant="primary"
                size="sm"
                onClick={() =>
                  navigate("/center-detail", { state: { id: dataInfo.id } })
                }
              >
                Center detail
              </Button>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <React.Fragment>
      <div id="ol-map" className="ol-map" />
      <div id="ol-popup" className="ol-popup">
        <span id="ol-popup-closer" className="ol-popup-closer" />
        <MakeHtml />
      </div>
    </React.Fragment>
  );
};

export default OLMap;

const loadGeoJson = (
  geojsonObject: { type: any; features: any },
  zIndex: number,
  layers: any
) => {
  clearGeoJson(zIndex, layers);

  let source = new VectorSource({
    features: new GeoJSON().readFeatures(geojsonObject, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    }),
  });

  layers[zIndex] = new VectorLayer({
    source: source,
    style: styles.Point,
  });

  layers[zIndex].setZIndex(zIndex);
  map.addLayer(layers[zIndex]);
  extend(bbox, source.getExtent());
};

const fitBound = () => {
  if (bbox) {
    setTimeout(() => {
      map.getView().fit(bbox, map.getSize());
      map.getView().setZoom(map.getView().getZoom() - 0.25);
    }, 100);
  }
};

const clearGeoJson = (zIndex: number, layers: string | any[]) => {
  if (layers) {
    for (var i = zIndex; i < layers.length; i++) {
      map.removeLayer(layers[i]);
    }
  }
};

const changeCursor = (evt: { originalEvent: any }) => {
  let pixel = map.getEventPixel(evt.originalEvent);
  let hit = map.hasFeatureAtPixel(pixel);
  map.getViewport().style.cursor = hit ? "pointer" : "";
};

let styles = {
  MultiPolygon: new Style({
    stroke: new Stroke({
      color: "blue",
      width: 1,
    }),
    fill: new Fill({
      color: "rgba(0, 0, 255, 0.1)",
    }),
  }),
  Point: new Style({
    image: new Icon({
      anchor: [0.5, 17],
      anchorXUnits: "fraction",
      anchorYUnits: "pixels",
      opacity: 0.95,
      src: mapMarker,
    }),
  }),
};
