import { Table } from "react-bootstrap";

const TableLayout = ({
  children,
  page,
}: {
  loading: boolean;
  data: any;
  children: any;
  page?: any;
  setPage?: any;
  handleGetData?: any;
}) => {
  return (
    <>
      <section className="bg-card mb-4 p-4">
        <Table className={page ? "mb-4" : "mb-0"} striped>
          {children}
        </Table>
      </section>
    </>
  );
};

export default TableLayout;
