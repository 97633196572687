import { Col, Container, Row } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"

export const GridLoading = () => {
    return (
        <>
            <Row>
                <Col md={4} sm={12} >
                    <Skeleton count={1} height={360} className="mb-4" borderRadius={10} />
                </Col>
                <Col md={4} sm={12}>
                    <Skeleton count={1} height={360} className="mb-4" borderRadius={10} />
                </Col>
                <Col md={4} sm={12}>
                    <Skeleton count={1} height={360} className="mb-4" borderRadius={10} />
                </Col>
            </Row>
            <Row>
                <Col md={4} sm={12}>
                    <Skeleton count={1} height={360} className="mb-4" borderRadius={10} />
                </Col>
                <Col md={4} sm={12}>
                    <Skeleton count={1} height={360} className="mb-4" borderRadius={10} />
                </Col>
                <Col md={4} sm={12}>
                    <Skeleton count={1} height={360} className="mb-4" borderRadius={10} />
                </Col>
            </Row>
            <Row>
                <Col md={4} sm={12}>
                    <Skeleton count={1} height={360} className="mb-4" borderRadius={10} />
                </Col>
                <Col md={4} sm={12}>
                    <Skeleton count={1} height={360} className="mb-4" borderRadius={10} />
                </Col>
                <Col md={4} sm={12}>
                    <Skeleton count={1} height={360} className="mb-4" borderRadius={10} />
                </Col>
            </Row>
        </>
    )
}
export const TableLoading = ({ rowCount = 15 }: { rowCount?: number }) => {
    return (
        <Skeleton count={rowCount} height={60} className="mb-2" borderRadius={10} />
    )
}


export const DetailLoading = () => {
    return (
        <Container className="mt-4">
            <Skeleton count={1} height={500} className="mb-4" borderRadius={10} />
            {/* <Skeleton count={1} height={200} borderRadius={10} /> */}
        </Container>
    )
}

export const ImageLoading = () => {
    return (
        <Skeleton count={1} height={180} width={180} borderRadius={10} />
    )
}

export const LoadingButtonLabel = ({ label, disabled }: { label: string, disabled: boolean }) => {
    return (
        <>
            {!disabled ? label : <div className='text-white'> <div className="spinner-grow spinner-grow-sm" />  Processing . . .</div>}
        </>
    )
}