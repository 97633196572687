import { useContext, useState, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FieldAccorion, Lang, PageBanner } from "../../../../components";
import FormFieldService from "../../../../components/common/FormFieldService";
import NRCPassport from "../../../../components/common/NRCPassport";
import Layout from "../../../../components/layout/Layout";
import { CourseAppFormContext } from "../../../../context/Form";
import { LangContext } from "../../../../context/Lang";
import { dateFormat2, handleDateFormat } from "../../../../services";
import { actionData, getMethod } from "../../../../services/api";
import OldStudentTable from "../oldstudent/OldStudentTable";

const LongCourseForm = () => {
  const [countryId, setCountryId] = useState("");
  const [regions, setRegions] = useState([]);
  const [nationality, setNationality] = useState([]);
  const [countries, setCounties] = useState([]);
  const [availableGender, setAvailableGender] = useState([]);
  const [courseAppForm, setCourseAppForm] = useContext(CourseAppFormContext);
  const {
    state: { language },
  } = useContext(LangContext);
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const temp: any = localStorage.getItem("RegisterData");
  const tempData = temp && JSON.parse(temp);
  localStorage.setItem("RegisterData", JSON.stringify(tempData));
  const centerInfo = tempData.centerinfo;
  const genders = [
    { key: "Female", label: <Lang label="female" />, customKey: true },
    { key: "Male", label: <Lang label="male" />, customKey: true },
  ];
  useEffect(() => {
    const data = tempData.answeredData;
    setCourseAppForm(data);
    let tempGender: any = [];
    tempData?.scheduleInfo?.availability?.forEach(
      (item: any, index: number) => {
        if (
          item.courseFor === "Male" &&
          item.applicantType === "Student" &&
          item.attendeeType === "Old"
        ) {
          let _temp: any = [];
          tempGender.forEach((item: any) => _temp.push(item));
          let isExist = false;
          _temp.forEach((item: any, index: number) => {
            if (item === "Male") {
              isExist = true;
            }
          });
          if (!isExist) {
            tempGender.push(<Lang label="male" />);
          }
        } else if (
          item.courseFor === "Female" &&
          item.applicantType === "Student" &&
          item.attendeeType === "Old"
        ) {
          let _temp: any = [];
          tempGender.forEach((item: any) => _temp.push(item));
          let isExist = false;
          _temp.forEach((item: any, index: number) => {
            if (item === "Female") {
              isExist = true;
            }
          });
          if (!isExist) {
            tempGender.push(<Lang label="female" />);
          }
        }
      }
    );
    setAvailableGender(tempGender);
    actionData(getMethod, `countries`).then((result: any) => {
      let temp: any = [];
      result.data.forEach((item: any) => {
        temp.push({ key: item.id, label: item.countryName, customKey: true });
      });
      setCounties(result.data);
      let nationality: any = [];
      result.data.forEach((item: any) => {
        nationality.push({
          key: item.countryName,
          label: item.countryName,
          customKey: true,
        });
      });
      setNationality(nationality);
    });
    // eslint-disable-next-line
  }, []);
  const fields = [
    {
      id: "fullName",
      label: <Lang label="name" />,
      value: courseAppForm.fullName,
      required: true,
    },
    {
      id: "age",
      label: <Lang label="age" />,
      value: courseAppForm.age,
      required: true,
    },
    // {
    //   id: "nationality",
    //   label: <Lang label="nationality" />,
    //   value: courseAppForm.nationality,
    //   required: true,
    //   data: nationality,
    //   type: "select",
    // },
    {
      id: "dateOfBirth",
      label: <Lang label="birthDay" />,
      type: "date",
      value: courseAppForm.dateOfBirth,
      required: true,
    },
    {
      id: "email",
      label: <Lang label="email" />,
      value: courseAppForm.email,
      required: true,
    },
    {
      id: "occupation",
      label: <Lang label="job" />,
      value: courseAppForm.occupation,
      required: true,
    },
    {
      id: "city",
      label: <Lang label="citys" />,
      value: courseAppForm.city,
      required: true,
    },
  ];
  const secondFields = [
    {
      id: "education",
      label: <Lang label="education" />,
      value: courseAppForm.education,
      required: true,
    },

    {
      id: "fatherName",
      label: <Lang label="fatherName" />,
      value: courseAppForm.fatherName,
      required: true,
    },
    {
      id: "phoneNumber",
      label: <Lang label="phoneNumber" />,
      value: courseAppForm.phoneNumber,
      required: true,
    },
    {
      id: "yearOfAppointment",
      label: <Lang label="at" />,
      value: courseAppForm.yearOfAppointment,
      required: true,
    },
    {
      id: "completeAddress",
      label: <Lang label="addressFull" />,
      inputType: "textarea",
      value: courseAppForm.completeAddress,
      required: true,
    },
  ];
  const addData: any = {
    fullName: courseAppForm.fullName,
    fatherName: courseAppForm.fatherName,
    dateOfBirth: courseAppForm.dateOfBirth,
    age: courseAppForm.age,
    gender: courseAppForm.gender,
    nationality: courseAppForm.nationality,
    idType: courseAppForm.idType,
    idCardNumber: courseAppForm.idCardNumber,
    phoneNumber: courseAppForm.phoneNumber,
    education: courseAppForm.education,
    occupation: courseAppForm.occupation,
    email: courseAppForm.email,
    country: courseAppForm.country,
    state: courseAppForm.state,
    city: courseAppForm.city,
    completeAddress: courseAppForm.completeAddress,
    appliedFrom: courseAppForm.appliedFrom,
    yearOfAppointment: courseAppForm.yearOfAppointment,
    fullyCommited: courseAppForm.fullyCommited,
    withoutOtherPractice: courseAppForm.withoutOtherPractice,
    yearOfDailyPractice: courseAppForm.yearOfDailyPractice,
    practiceDetail: courseAppForm.practiceDetail,
    maintainedFivePrecepts: courseAppForm.maintainedFivePrecepts,
    abstainedSexualMisconduct: courseAppForm.abstainedSexualMisconduct,
    abstainedAlcoholDrugs: courseAppForm.abstainedAlcoholDrugs,
    meetAllRequirements: courseAppForm.meetAllRequirements,
    requirementsMismatchReason: courseAppForm.requirementsMismatchReason,
    first10DCourseDate: courseAppForm.first10DCourseDate,
    first10DCourseLocation: courseAppForm.first10DCourseLocation,
    first10DCourseTeacher: courseAppForm.first10DCourseTeacher,
    recent10DCourseDate: courseAppForm.recent10DCourseDate,
    recent10DCourseLocation: courseAppForm.recent10DCourseLocation,
    recent10DCourseTeacher: courseAppForm.recent10DCourseTeacher,
    recentLongCourseDate: courseAppForm.recentLongCourseDate,
    recentLongCourseLocation: courseAppForm.recentLongCourseLocation,
    recentLongCourseTeacher: courseAppForm.recentLongCourseTeacher,
    numOf10DServed: courseAppForm.numOf10DServed,
    servedOther: courseAppForm.numOf10DServed,
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    const target = evt.target;
    const name = target.id;
    let value;
    if (target.type === "checkbox") {
      value = target.checked;
    } else if (target.type === "number" || target.type === "select-one") {
      if (target.value === "") {
        value = "";
      } else {
        value = target.value;
        if (wantString) {
          value = target.value;
        } else {
          value = parseFloat(target.value);
        }
      }
    } else {
      value = target.value;
    }
    setCourseAppForm({ ...courseAppForm, [name]: value });
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      toast.error("Please fill data!");
    } else {
      // handleNext();
      navigate("/long-course-registration");
      const temp: any = localStorage.getItem("RegisterData");
      const tempData = temp && JSON.parse(temp);
      tempData.answeredData = addData;
      localStorage.setItem("RegisterData", JSON.stringify(tempData));
    }
    setValidated(true);
  };
  const handleSelectCountry = (e: any) => {
    const value = e.target.value;
    var index = e.nativeEvent.target.selectedIndex;
    var text = e.nativeEvent.target[index].text;
    setCourseAppForm({
      ...courseAppForm,
      country: text,
    });
    setCountryId(value);
    handleGetRegions(value);
  };
  const handleGetRegions = async (countryId: string) => {
    actionData(getMethod, `states?countryId=` + countryId).then((result) => {
      setRegions(result.data);
    });
  };
  return (
    <>
      <Layout>
        <PageBanner title={<Lang label="courseAppForm" />} label="" />
        <Container className="my-5">
          <Container className="my-3">
            <div className="alert alert-info mt-4" role="alert">
              <label className="text-muted mb-2">
                <Lang label="available" />
              </label>
              {availableGender.length > 0 &&
                availableGender?.map((item: any, index: number) => (
                  <h6 key={index}>- {item}</h6>
                ))}
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={4}>
                  <h6 className=" my-3">
                    <Lang label="gender" />
                  </h6>
                  <Form.Select
                    required
                    value={courseAppForm.gender}
                    onChange={(e) =>
                      setCourseAppForm({
                        ...courseAppForm,
                        gender: e.target.value,
                      })
                    }
                  >
                    {genders.map((item: any, index: number) => (
                      <option
                        value={
                          item.customKey
                            ? item.key
                            : item.key === "value"
                            ? item.value
                            : item.label
                        }
                        key={index}
                      >
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col md={4}>
                  <h6 className=" my-3">{<Lang label="centerName" />}</h6>
                  <Form.Control
                    type="text"
                    className="mb-3"
                    placeholder=""
                    value={
                      language === "EN"
                        ? centerInfo?.centerNameEN
                        : centerInfo?.centerNameMM
                    }
                    disabled
                  />
                  <Form.Control
                    as="textarea"
                    className="mb-3"
                    rows={3}
                    placeholder=" "
                    value={
                      language === "EN"
                        ? centerInfo?.addressEN
                        : centerInfo?.addressMM
                    }
                    disabled
                  />
                </Col>
                <Col md={4}>
                  <h6 className=" my-3">
                    <Lang label="courseDate" />
                  </h6>
                  <Form.Control
                    type="date"
                    className="mb-3"
                    placeholder=""
                    value={handleDateFormat(
                      tempData.scheduleInfo?.startDate,
                      dateFormat2
                    )}
                    onChange={(e) =>
                      setCourseAppForm({
                        ...courseAppForm,
                        courseStartDate: e.target.value,
                      })
                    }
                  />
                  <Form.Control
                    type="date"
                    className="mb-3"
                    placeholder=""
                    value={handleDateFormat(
                      tempData.scheduleInfo?.endDate,
                      dateFormat2
                    )}
                    onChange={(e) =>
                      setCourseAppForm({
                        ...courseAppForm,
                        courseEndDate: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <div className="bg-card p-4">
                <Col className="mb-3">
                  <Form.Check
                    type="checkbox"
                    id="isForeigner"
                    label={<Lang label="isForeigner" />}
                    checked={courseAppForm.isForeigner}
                    onChange={() =>
                      setCourseAppForm({
                        ...courseAppForm,
                        isForeigner: !courseAppForm.isForeigner,
                      })
                    }
                  />
                </Col>
                <FormFieldService
                  fields={fields}
                  handleChange={(e: any) => handleChange(e, true)}
                  formData={courseAppForm}
                  setFormData={setCourseAppForm}
                  colCount={4}
                  bg={false}
                />
                <Row>
                  <Col md={4}>
                    <Form.Label>
                      <Lang label="country"></Lang>
                    </Form.Label>
                    <Form.Control
                      required
                      as="select"
                      aria-label="Select"
                      className="mb-4"
                      id="countryId"
                      value={countryId || ""}
                      onChange={handleSelectCountry}
                    >
                      <option value="">Select</option>
                      {countries.map((item: any, index: number) => (
                        <option value={item.id} key={index}>
                          {item.countryName}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col md={4}>
                    <Form.Label>
                      <Lang label="state" />
                    </Form.Label>
                    <Form.Control
                      required
                      as="select"
                      aria-label="Select"
                      className="mb-4"
                      id="state"
                      value={courseAppForm.state || ""}
                      onChange={(e) => handleChange(e, true)}
                    >
                      <option value="">Select</option>
                      {regions.map((item: any, index: number) => (
                        <option value={item.areaName} key={index}>
                          {item.areaName}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-4 input-form-control">
                      <Form.Label>
                        <Lang label="nationality"></Lang>
                      </Form.Label>
                      <Form.Control
                        required
                        as="select"
                        aria-label="Select"
                        className="mb-4"
                        id="nationality"
                        value={courseAppForm.nationality}
                        onChange={(e) => handleChange(e, true)}
                      >
                        <option value="">
                          <Lang label="select" />
                        </option>
                        {nationality.map((item: any, index: number) => (
                          <option value={item.key} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <NRCPassport />
                <FormFieldService
                  fields={secondFields}
                  handleChange={handleChange}
                  formData={courseAppForm}
                  setFormData={setCourseAppForm}
                  colCount={4}
                  bg={false}
                />
              </div>
              <div className="my-3">
                <FieldAccorion label={<Lang label="lq-1" />}>
                  <Form.Check
                    inline
                    label={<Lang label="yes" />}
                    type={"radio"}
                    id={"fullyCommitedYes"}
                    name="lq-1"
                    checked={courseAppForm.fullyCommited}
                    onChange={() =>
                      setCourseAppForm({
                        ...courseAppForm,
                        fullyCommited: true,
                      })
                    }
                  />
                  <Form.Check
                    inline
                    label={<Lang label="no" />}
                    name="lq-1"
                    type={"radio"}
                    id={`fullyCommitedNo`}
                    checked={!courseAppForm.fullyCommited}
                    onChange={() =>
                      setCourseAppForm({
                        ...courseAppForm,
                        fullyCommited: false,
                      })
                    }
                  />
                </FieldAccorion>
                <FieldAccorion label={<Lang label="lq-2" />}>
                  <Form.Check
                    inline
                    label={<Lang label="yes" />}
                    type={"radio"}
                    id={"withoutOtherPracticeYes"}
                    name="lq-2"
                    checked={courseAppForm.withoutOtherPractice}
                    onChange={() =>
                      setCourseAppForm({
                        ...courseAppForm,
                        withoutOtherPractice: true,
                      })
                    }
                  />
                  <Form.Check
                    inline
                    label={<Lang label="no" />}
                    name="lq-2"
                    type={"radio"}
                    id={`withoutOtherPracticeNo`}
                    checked={!courseAppForm.withoutOtherPractice}
                    onChange={() =>
                      setCourseAppForm({
                        ...courseAppForm,
                        withoutOtherPractice: false,
                      })
                    }
                  />
                </FieldAccorion>
                <FieldAccorion label={<Lang label="lq-3" />}>
                  <Form.Control
                    required
                    as="textarea"
                    rows={1}
                    value={courseAppForm.yearOfDailyPractice}
                    onChange={(e) =>
                      setCourseAppForm({
                        ...courseAppForm,
                        yearOfDailyPractice: e.target.value,
                      })
                    }
                  />
                </FieldAccorion>
                <FieldAccorion label={<Lang label="lq-3-detail" />}>
                  <Form.Control
                    required
                    as="textarea"
                    rows={3}
                    value={courseAppForm.practiceDetail}
                    onChange={(e) =>
                      setCourseAppForm({
                        ...courseAppForm,
                        practiceDetail: e.target.value,
                      })
                    }
                  />
                </FieldAccorion>
                <FieldAccorion label={<Lang label="lq-4" />}>
                  <div className="lh-5">
                    <div className="mb-2">
                      <Lang label="lq-4-1" />
                    </div>
                    <div className="mb-2">
                      <Lang label="lq-4-2" />
                    </div>
                    <div className="mb-2">
                      <Lang label="lq-4-3" />
                    </div>
                    <div className="mb-2">
                      <Lang label="lq-4-4" />
                    </div>
                    <div className="mb-2">
                      <Lang label="lq-4-5" />
                    </div>
                    <div className="mb-2">
                      <Lang label="lq-4-info" />
                    </div>
                  </div>
                  <div className="my-2 d-flex justify-content-between">
                    <Lang label="lq-4-q-1" />
                    <div className="d-flex">
                      <Form.Check
                        inline
                        label={<Lang label="yes" />}
                        type={"radio"}
                        id={"maintainedFivePreceptsYes"}
                        name="maintainedFivePrecepts"
                        checked={courseAppForm.maintainedFivePrecepts}
                        onChange={() =>
                          setCourseAppForm({
                            ...courseAppForm,
                            maintainedFivePrecepts: true,
                          })
                        }
                      />
                      <Form.Check
                        inline
                        label={<Lang label="no" />}
                        name="maintainedFivePrecepts"
                        type={"radio"}
                        id={`maintainedFivePreceptsNo`}
                        checked={!courseAppForm.maintainedFivePrecepts}
                        onChange={() =>
                          setCourseAppForm({
                            ...courseAppForm,
                            maintainedFivePrecepts: false,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="mb-2 d-flex justify-content-between">
                    <Lang label="lq-4-q-2" />
                    <div className="d-flex">
                      <Form.Check
                        inline
                        label={<Lang label="yes" />}
                        type={"radio"}
                        id={"abstainedSexualMisconductYes"}
                        name="abstainedSexualMisconduct  "
                        checked={courseAppForm.abstainedSexualMisconduct}
                        onChange={() =>
                          setCourseAppForm({
                            ...courseAppForm,
                            abstainedSexualMisconduct: true,
                          })
                        }
                      />
                      <Form.Check
                        inline
                        label={<Lang label="no" />}
                        name="abstainedSexualMisconduct  "
                        type={"radio"}
                        id={`abstainedSexualMisconductNo`}
                        checked={!courseAppForm.abstainedSexualMisconduct}
                        onChange={() =>
                          setCourseAppForm({
                            ...courseAppForm,
                            abstainedSexualMisconduct: false,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="mb-2 d-flex justify-content-between">
                    <Lang label="lq-4-q-3" />
                    <div className="d-flex">
                      <Form.Check
                        inline
                        label={<Lang label="yes" />}
                        type={"radio"}
                        id={"abstainedAlcoholDrugsYes     "}
                        name="abstainedAlcoholDrugs        "
                        checked={courseAppForm.abstainedAlcoholDrugs}
                        onChange={() =>
                          setCourseAppForm({
                            ...courseAppForm,
                            abstainedAlcoholDrugs: true,
                          })
                        }
                      />
                      <Form.Check
                        inline
                        label={<Lang label="no" />}
                        name="abstainedAlcoholDrugsNo"
                        type={"radio"}
                        id={`abstainedAlcoholDrugs      `}
                        checked={!courseAppForm.abstainedAlcoholDrugs}
                        onChange={() =>
                          setCourseAppForm({
                            ...courseAppForm,
                            abstainedAlcoholDrugs: false,
                          })
                        }
                      />
                    </div>
                  </div>
                </FieldAccorion>
                <FieldAccorion label={<Lang label="lq-5" />}>
                  <Form.Check
                    inline
                    label={<Lang label="yes" />}
                    type={"radio"}
                    id={"meetAllRequirementsYes"}
                    name="meetAllRequirements"
                    checked={courseAppForm.meetAllRequirements}
                    onChange={() =>
                      setCourseAppForm({
                        ...courseAppForm,
                        meetAllRequirements: true,
                        requirementsMismatchReason: "",
                      })
                    }
                  />
                  <Form.Check
                    inline
                    label={<Lang label="no" />}
                    type={"radio"}
                    id={"meetAllRequirementsNo"}
                    name="meetAllRequirements"
                    checked={!courseAppForm.meetAllRequirements}
                    onChange={() =>
                      setCourseAppForm({
                        ...courseAppForm,
                        meetAllRequirements: false,
                      })
                    }
                  />
                  {!courseAppForm.meetAllRequirements && (
                    <Form.Control
                      required
                      as="textarea"
                      rows={3}
                      className="mt-3"
                      value={courseAppForm.requirementsMismatchReason}
                      onChange={(e) =>
                        setCourseAppForm({
                          ...courseAppForm,
                          requirementsMismatchReason: e.target.value,
                        })
                      }
                    />
                  )}
                </FieldAccorion>

                <FieldAccorion label={<Lang label="lq-6" />}>
                  <Row className="mb-3">
                    <h6>
                      <Lang label="first10day" />
                    </h6>
                    <Col md={4}>
                      <h6 className=" my-3">
                        <Lang label="when" />
                      </h6>
                      <Form.Control
                        required
                        type="date"
                        placeholder=" "
                        value={courseAppForm.first10DCourseDate}
                        onChange={(e) =>
                          setCourseAppForm({
                            ...courseAppForm,
                            first10DCourseDate: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <h6 className=" my-3">
                        <Lang label="where" />
                      </h6>
                      <Form.Control
                        required
                        type="text"
                        placeholder=""
                        value={courseAppForm.first10DCourseLocation}
                        onChange={(e) =>
                          setCourseAppForm({
                            ...courseAppForm,
                            first10DCourseLocation: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <h6 className=" my-3">
                        <Lang label="whichteacher" />
                      </h6>
                      <Form.Control
                        required
                        type="text"
                        placeholder=""
                        value={courseAppForm.first10DCourseTeacher}
                        onChange={(e) =>
                          setCourseAppForm({
                            ...courseAppForm,
                            first10DCourseTeacher: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <h6>
                      <Lang label="mostRecent" />
                    </h6>
                    <Col md={4}>
                      <h6 className=" my-3">
                        <Lang label="when" />
                      </h6>
                      <Form.Control
                        required
                        type="date"
                        placeholder=" "
                        value={courseAppForm.recent10DCourseDate}
                        onChange={(e) =>
                          setCourseAppForm({
                            ...courseAppForm,
                            recent10DCourseDate: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <h6 className=" my-3">
                        <Lang label="where" />
                      </h6>
                      <Form.Control
                        required
                        type="text"
                        placeholder=""
                        value={courseAppForm.recent10DCourseLocation}
                        onChange={(e) =>
                          setCourseAppForm({
                            ...courseAppForm,
                            recent10DCourseLocation: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <h6 className=" my-3">
                        <Lang label="whichteacher" />
                      </h6>
                      <Form.Control
                        required
                        type="text"
                        placeholder=""
                        value={courseAppForm.recent10DCourseTeacher}
                        onChange={(e) =>
                          setCourseAppForm({
                            ...courseAppForm,
                            recent10DCourseTeacher: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <h6>
                      <Lang label="longcourse" />
                    </h6>
                    <Col md={4}>
                      <h6 className=" my-3">
                        <Lang label="when" />
                      </h6>
                      <Form.Control
                        required
                        type="date"
                        placeholder=" "
                        value={courseAppForm.recentLongCourseDate}
                        onChange={(e) =>
                          setCourseAppForm({
                            ...courseAppForm,
                            recentLongCourseDate: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <h6 className=" my-3">
                        <Lang label="where" />
                      </h6>
                      <Form.Control
                        required
                        type="text"
                        placeholder=""
                        value={courseAppForm.recentLongCourseLocation}
                        onChange={(e) =>
                          setCourseAppForm({
                            ...courseAppForm,
                            recentLongCourseLocation: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <h6 className=" my-3">
                        <Lang label="whichteacher" />
                      </h6>
                      <Form.Control
                        required
                        type="text"
                        placeholder=""
                        value={courseAppForm.recentLongCourseTeacher}
                        onChange={(e) =>
                          setCourseAppForm({
                            ...courseAppForm,
                            recentLongCourseTeacher: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <FieldAccorion label={<Lang label="lq-6-5" />}>
                    <Form.Control
                      required
                      type="number"
                      value={courseAppForm.numOf10DServed}
                      onChange={(e) =>
                        setCourseAppForm({
                          ...courseAppForm,
                          numOf10DServed: e.target.value,
                        })
                      }
                    />
                  </FieldAccorion>
                  <FieldAccorion label={<Lang label="lq-6-6" />}>
                    <Form.Control
                      required
                      as="textarea"
                      rows={3}
                      value={courseAppForm.servedOther}
                      onChange={(e) =>
                        setCourseAppForm({
                          ...courseAppForm,
                          servedOther: e.target.value,
                        })
                      }
                    />
                  </FieldAccorion>
                  <OldStudentTable />
                </FieldAccorion>
              </div>

              <div className="d-flex flex-center my-5">
                <Button
                  className="me-3 btn-blue fw-bold"
                  onClick={() => navigate("/centers")}
                >
                  <Lang label="cancle" />
                </Button>
                <Button type="submit">
                  <Lang label="next" />
                </Button>
              </div>
            </Form>
          </Container>
        </Container>
      </Layout>
    </>
  );
};
export default LongCourseForm;
