import { Col, Container, Image, Nav, Navbar, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Lang } from '..'
import { IconEmail, IconGeo, IconPhone } from '../common/Icons'

const Footer = () => {
  const history = useNavigate()
  const footerNav = [
    {
      icon: <IconGeo size={20} color={'white'} />,
      label: <Lang label="address" />,
    },
    { icon: <IconEmail size={18} />, label: 'itteammyanmar@gmail.com' },
    {
      icon: <IconPhone size={18} color="white" />,
      label: <Lang label="phone" />,
    },
  ]
  const navLinks = [
    { route: '/', label: <Lang label="home" /> },
    { route: '/centers', label: <Lang label="centers" /> },
  ]

  return (
    <>
      <footer className="bg-footer">
        <Container className="pb-3 pt-5">
          <Row>
            <Col lg={4} md={4} sm={12} className="mb-2 mt-3">
              <>
                <div className="align-left mb-2">
                  <Navbar.Brand href="/" className="align-left mb-3">
                    <Image src="images/logo.png" width="60" />
                  </Navbar.Brand>
                </div>
                <span className="text-white text-left">
                  {' '}
                  <Lang label="title" />
                </span>
              </>
            </Col>
            <Col lg={4} md={4} sm={12} className="mb-2 mt-3">
              {navLinks.map((item: any, index: number) => (
                <Nav.Link
                  onClick={() => history(item.route)}
                  className="text-white text-left footer-links"
                  key={index}
                >
                  <span className="ms-2">{item.label}</span>{' '}
                </Nav.Link>
              ))}
            </Col>
            <Col lg={4} md={4} sm={12} className="mb-2 ">
              {footerNav.map((item: any, index: number) => (
                <div className="d-flex  mt-3" key={index}>
                  <span className="text-white flex-end">
                    {item.icon}
                    <span className="ps-2 ">{item.label}</span>
                  </span>
                </div>
              ))}
            </Col>
            <hr className="text-muted mt-5" />
            <Col>
              <div className="text-center">
                <a
                  className="text-muted"
                  href="https://hbsenterprise.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  &copy; {new Date().getFullYear()} Hybrid Business Service Co.,
                  Ltd.
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}
export default Footer
